<template>
  <div v-if="showList" class="multi-video-component">
    <v-row no-gutters class="d-flex flex-wrap justify-space-between w-100">
      <v-col v-for="video in videos" :key="video.id" cols="5" @click="playSingleVideo(video.id)"
        class="multi-video-component__box">
        <img :src="video.image" alt="" class="multi-video-component__box--ico">
        <p>{{ video.text }}</p>
      </v-col>
    </v-row>
  </div>
  <v-row no-gutters v-if="!showList" class="h-100">
    <slider-video :video-index="clickedVideo" :videos="videos" />
  </v-row>
</template>

<script>
import SliderVideo from "@/components/Section/SliderVideoComponent";

export default {
  name: 'MultiVideo',
  components: {
    SliderVideo,
  },
  data() {
    return {
      showList: true,
      clickedVideo: null,
    }
  },
  props: {
    videos: Object,
  },
  methods: {
    playSingleVideo(videoId) {
      this.showList = false;
      this.clickedVideo = videoId;
    }
  }
}
</script>

<style lang="scss">
.multi-video-component {

  &__box {
    min-height: 13.25vh;
    border-radius: 0 .5rem 0 .5rem;
    -webkit-box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
    -moz-box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
    box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
    margin: 1.4vh 2.3vw;
    padding: 0.5em 0;

    p {
      font-family: "Invention-Regular";
      font-size: .75rem;
      line-height: 1rem;
      text-align: center;
      padding: 0.75rem;
    }

    &--ico {
      display: block;
      width: 1.75rem;
      height: auto;
      margin: 1rem auto 0;
    }
  }
}
</style>
