<template>
  <div class="quiz d-flex flex-column">
    <MedicalMenuHeader :title="quizTitle" imgPath="quiz-ico.png" />
    <div v-if="currentQuestion" class="quiz-area h-100 d-flex flex-column justify-space-between align-center">
      <quiz-question :key="questionIndex" :question="currentQuestion" v-if="!quizDone" @answer="setAnswer"
        :answer-selected="answers[currentQuestion.question]" />
      <p v-if="!quizDone">{{ questionIndex + 1 }} / {{ currentQuiz.length }} </p>
      <quiz-results v-if="quizDone" :questions="currentQuiz" :answers="answers" />
      <div class="d-flex">
        <a v-if="!quizDone" @click="previousQuestion">
          <ButtonMain title="Poprzednie" :is-disabled="this.questionIndex <= 0" img-path="left-arrow.png" />
        </a>
        <a v-if="!quizDone && questionIndex + 1 < currentQuiz.length" @click="nextQuestion">
          <ButtonMain title="Następne" :is-disabled="false" img-path="right-arrow.png" />
        </a>
        <a v-if="!quizDone && questionIndex + 1 >= currentQuiz.length" @click="endQuiz">
          <ButtonMain :is-disabled="false" title="Wyniki" img-path="right-arrow.png" />
        </a>
        <ButtonMain v-if="quizDone" title="Strona główna" url-path="home" :is-disabled="false"
          img-path="right-arrow.png" />
      </div>
    </div>
    <div v-if="!currentQuestion">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import QuizData from '@/components/Quiz/quiz.json';
import QuizQuestion from '@/components/Quiz/QuizQuestionComponent.vue';
import QuizResults from '@/components/Quiz/QuizResults.vue';
import ButtonMain from "@/components/Buttons/ButtonMainComponent";

export default {
  name: 'QuizComponent',
  props: {
    quizId: Number,
  },
  components: {
    MedicalMenuHeader,
    ButtonMain,
    QuizQuestion,
    QuizResults
  },
  data() {
    return {
      quizDone: false,
      loaded: false,
      questionIndex: 0,
      currentQuiz: null,
      currentQuestion: null,
      answers: [],
      quizTitle: 'Quiz',
      lockNextQuestionButton: true,
    };
  },
  emits: ['survey-loader'],
  created() {
    this.loadQuizData();
  },
  beforeUnmount() {
    this.resetQuiz();
  },
  methods: {
    loadQuizData() {
      const quiz = QuizData.find((quiz) => quiz.id === this.quizId);
      this.currentQuiz = quiz['questions'];
      this.currentQuestion = this.currentQuiz[this.questionIndex];
    },

    setAnswer(value) {
      this.answers[this.currentQuiz[this.questionIndex].question] = value;
      this.lockNextQuestionButton = false;
    },

    nextQuestion() {
      if (!this.lockNextQuestionButton && this.questionIndex < this.currentQuiz.length) {
        this.questionIndex++;
        this.currentQuestion = this.currentQuiz[this.questionIndex];
        if (!this.answers[this.currentQuiz[this.questionIndex].question]) {
          this.lockNextQuestionButton = true;
        }
      }
    },

    previousQuestion() {
      if (this.questionIndex > 0) {
        this.questionIndex--;
        this.currentQuestion = this.currentQuiz[this.questionIndex];
        this.lockNextQuestionButton = false;
      }
    },

    endQuiz() {
      if (!this.lockNextQuestionButton) {
        this.quizDone = true;
        this.quizTitle = 'Quiz - Wyniki';

        this.$emit('survey-loader', 2);
      }
    },

    resetQuiz() {
      this.quizDone = false;
      this.questionIndex = 0;
      this.currentQuiz = null;
      this.currentQuestion = null;
      this.answers = [];
      this.quizTitle = 'Quiz';
    },
  },
};
</script>

<style lang="scss">
.quiz {
  width: 100%;

  .quiz-area {
    background-color: rgba(255, 255, 255, 1);
    background-image: linear-gradient(190deg, rgba(255, 255, 255, 1) 46%, rgba(229, 239, 238, 1) 100%);
    border-radius: 0em 1em;
    padding: 4% 0 3%;
    margin-bottom: 0.75rem !important;
    font-family: "Invention-Bold";
    font-size: .875rem;
    line-height: 1.25rem;
    position: relative;
    text-align: left;

    &::before {
      content: '';
      position: absolute;
      bottom: -.75rem;
      right: -.75rem;
      width: .75rem;
      height: .75rem;
      background-color: #01d0cf;
      z-index: -1;
    }

    >p {
      margin: .5rem 0 1rem;
    }

  }


}
</style>
