<template>
  <div class="pdf-viewer">
    <pdf v-for="page in limitPages + offset" :src="'/pdf/' + pdfPath" :page="page" :key="page" :resize="true" />
    <div v-if="showPagesLoadButton" class="load-next-pages-button" @click="loadNextPages">Załaduj kolejne strony</div>
  </div>
</template>

<script>
import pdf from 'pdfvuer';

export default {
  name: 'PdfViewer',
  components: {
    pdf,
  },
  props: {
    pdfPath: String,
    pages: Number
  },
  data() {
    return {
      limitPages: 5,
      offset: 0,
      showPagesLoadButton: true,
    };
  },
  created() {
    if (this.limitPages > this.pages) {
      this.limitPages = this.pages;
    }
  },
  methods: {
    loadNextPages() {
      if (this.pages === this.offset + this.limitPages) {
        this.showPagesLoadButton = false;
      } else if (this.pages > this.offset + this.limitPages) {
        this.offset += this.limitPages;
      } else {
        this.offset += this.pages - this.offset - this.limitPages;
        this.showPagesLoadButton = false;
      }
    }
  }
}
</script>

<style lang="scss">
.pdf-viewer {
  position: absolute;
  width: 50%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 72%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  -webkit-box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
  -moz-box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
  box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
  background-color: #ffffff;
}

.load-next-pages-button {
  width: 60%;
  padding: 0rem 1rem;
  margin: 1rem auto;
  color: white;
  background-color: #00857c;
  text-align: center;
  font-size: .75rem;
  line-height: 1.5rem;
  font-family: "Invention-Regular";
  padding: .5rem 1.25rem;
  background-color: #00857c;
  color: #ffffff;
  border: .02rem solid #b9c9db;
  -webkit-box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
  -moz-box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
  box-shadow: 0px 0px 10px 5px rgba(178, 216, 213, 1);
}
</style>
