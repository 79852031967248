import LungCancerHomeView from '@/views/Medical/LungCancer/LungCancerHomeView'
import LungCancerVideoView from '@/views/Medical/LungCancer/LungCancerVideoView'
import LungCancerChplView from '@/views/Medical/LungCancer/LungCancerChplView'
import LungCancerEducationalMaterialsView from "@/views/Medical/LungCancer/LungCancerEducationalMaterialsView";

const routes = [
    {
        path: '/niedrobnokomorkowy-rak-pluca',
        name: 'lung_cancer',
        component: LungCancerHomeView
    },
    {
        path: '/niedrobnokomorkowy-rak-pluca/video',
        name: 'lung_cancer_video',
        component: LungCancerVideoView
    },
    {
        path: '/niedrobnokomorkowy-rak-pluca/chpl',
        name: 'lung_cancer_chpl',
        component: LungCancerChplView
    },
    {
        path: '/niedrobnokomorkowy-rak-pluca/materialy-edukacyjne',
        name: 'lung_cancer_educational_materials',
        component: LungCancerEducationalMaterialsView
    },
];

export default routes;
