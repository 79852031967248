<template>
  <v-dialog v-model="internalDialogState">
    <div class="dialog-logo">
      <img :src="require('@/assets/img/main/keytruda-logo.png')" />
    </div>
    <v-col cols="7" class="dialog pa-0" :class="materialsData.class">
      <dialog-menu v-if="!materialsData.menuInFooter" :menu-elements="materialsData.menu"
        :active-slide-index="activeSlideIndex" @slide-index-update="updateSlideIndex" />
      <dialog-content :debug="debug" :slides-data="materialsData.slides" :active-slide-index="activeSlideIndex"
        @slide-index-update="updateSlideIndex" @clicked-popup="openSubDialog" />
      <dialog-menu v-if="materialsData.menuInFooter" :menu-elements="materialsData.menu"
        :active-slide-index="activeSlideIndex" @slide-index-update="updateSlideIndex" />
      <div class="dialog-close" @click="internalDialogState = false">
        <img :src="require('@/assets/img/icons/close-material.png')" />
      </div>
    </v-col>
    <div v-if="qrCodeImagePath" class="dialog-qr-code">
      <img :src="require('@/assets/img/educational-materials/' + qrCodeImagePath)" />
      <p>Pobierz</p>
    </div>
  </v-dialog>
  <sub-dialog v-if="subDialogState" :dialog-state="subDialogState" @input="subDialogState = $event"
    :sub-dialog-data="subDialogData" :active-slide-index="activeSlideIndex" :debug="debug"
    :sub-dialog-index="subDialogIndex" @close-sub-dialog="subDialogState = false" />
</template>

<script>
import DialogMenu from "@/components/Section/Dialog/DialogMenuComponent";
import DialogContent from "@/components/Section/Dialog/DialogContentComponent";
import SubDialog from "@/components/Section/Dialog/SubDialogComponent";

export default {
  name: 'DialogMainSectionComponent',
  components: {
    DialogMenu,
    DialogContent,
    SubDialog,
  },
  props: {
    dialogState: Boolean,
    qrCodeImagePathProp: String,
    materialsData: Object,
    debug: Boolean,
    activeSlideIndexProp: Number,
  },
  emits: ['input'],
  beforeUnmount() {
    this.activeSlideIndex = 0;
  },
  created() {
    if (this.qrCodeImagePathProp) {
      this.qrCodeImagePath = this.qrCodeImagePathProp;
    }
    if (this.activeSlideIndexProp) {
      this.activeSlideIndex = this.activeSlideIndexProp;
    }
  },
  data() {
    return {
      qrCodeImagePath: null,
      activeSlideIndex: 0,
      subDialogState: false,
      popupIndex: null,
      subDialogData: null,
      subDialogIndex: null,
    }
  },
  methods: {
    updateSlideIndex(slideIndex) {
      this.activeSlideIndex = slideIndex;
    },
    openSubDialog(index) {
      this.subDialogIndex = index;
      this.subDialogData = this.materialsData.slides[this.activeSlideIndex].popups[index];
      this.subDialogState = true;
    },
    resetIndex() {
      this.activeSlideIndex = 0;
    },
  },
  computed: {
    internalDialogState: {
      get() {
        return this.dialogState;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
}
</script>

<style lang="scss">
.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 0 .75rem 0 .75rem;
  -webkit-box-shadow: 0px 0px 40px 10px rgba(3, 100, 94, 0.28);
  -moz-box-shadow: 0px 0px 40px 10px rgba(3, 100, 94, 0.28);
  box-shadow: 0px 0px 40px 10px rgba(3, 100, 94, 0.28);

  &::before {
    content: '';
    position: absolute;
    bottom: -1.5rem;
    right: -1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #01d0cf;
    z-index: -1;
  }
}

.dialog-qr-code {
  position: absolute;
  right: 0;
  margin: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-color: white;

  img {
    display: block;
    width: auto;
    height: 6.5rem;
    padding: .5rem 1.125rem;
    border-left: 3px solid #ffffff;
  }

  p {
    font-family: "Invention-Bold";
    font-size: .75rem;
    line-height: 2rem;
    background-color: #00827a;
    border-left: 3px solid #00827a;
    color: #ffffff;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 2rem solid #00827a;
      border-left: 1.18rem solid transparent;
      left: -1.165rem;
      bottom: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 4.25rem solid transparent;
    border-bottom: 4.25rem solid transparent;
    border-right: 2.5rem solid #ffffff;
    left: -2.49rem;
    z-index: -1;
  }
}


.dialog-logo {
  position: absolute;
  right: 0;
  top: 0;

  img {
    display: block;
    width: 12rem;
    height: auto;
  }
}


.dialog-close {
  position: absolute;
  right: -2.5rem;
  top: 5%;
  background-color: #00827a;
  z-index: -1;
  padding: .75rem;

  img {
    display: block;
    width: 1rem;
    height: auto;
  }
}

.v-overlay {
  &__content {
    padding: 0 !important;
    margin: 0 !important;
    height: 100% !important;
    width: 100% !important;
    max-height: 100% !important;
    max-width: 100% !important;
    background-image: url("~@/assets/img/main/materials-overlay.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
