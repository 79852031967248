import RenalCellCarcinomaHomeView from '@/views/Medical/RenalCellCarcinoma/RenalCellCarcinomaHomeView.vue'
import RenalCellCarcinomaEducationalMaterialsView from "@/views/Medical/RenalCellCarcinoma/RenalCellCarcinomaEducationalMaterialsView";
import RenalCellCarcinomaVideoView from "@/views/Medical/RenalCellCarcinoma/RenalCellCarcinomaVideoView";
import RenalCellCarcinomaChplView from "@/views/Medical/RenalCellCarcinoma/RenalCellCarcinomaChplView";


const routes = [
    {
        path: '/nerwokomorkowy',
        name: 'renal_cell_carcinoma',
        component: RenalCellCarcinomaHomeView
    },
    {
        path: '/nerwokomorkowy/materialy-edukacyjne',
        name: 'renal_cell_carcinoma_materials',
        component: RenalCellCarcinomaEducationalMaterialsView
    },
    {
        path: '/nerwokomorkowy/video',
        name: 'renal_cell_carcinoma_video',
        component: RenalCellCarcinomaVideoView
    },
    {
        path: '/nerwokomorkowy/chpl',
        name: 'renal_cell_carcinoma_chpl',
        component: RenalCellCarcinomaChplView
    },
];

export default routes;
