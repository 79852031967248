<template>
  <router-link v-if="urlPath" :to="{ name: urlPath }">
    <div class="medical-menu-tile-component d-flex align-center ">
      <img :src="require('@/assets/img/medical/' + imgPath)" />
      <p>{{ title }}</p>
    </div>
  </router-link>
  <div v-if="!urlPath" class="medical-menu-tile-component d-flex align-center ">
    <img :src="require('@/assets/img/medical/' + imgPath)" />
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'MedicalMenuTile',
  props: {
    title: String,
    urlPath: String,
    imgPath: String,
  }
}
</script>

<style lang="scss">

.home-category {
  .medical-menu-tile-component {
    p {
      text-align: left;
    }
  }
}

.medical-menu-tile-component {
  background-color: rgba(255, 255, 255, 1);
  background-image: linear-gradient(190deg, rgba(255, 255, 255, 1) 46%, rgba(229, 239, 238, 1) 100%);
  border-radius: 0rem 1rem;
  padding: .5rem .5rem;
  font-family: "Invention-Bold";
  font-size: 1rem;
  line-height: 1.25rem;
  width: 84%;
  min-height: 62%;
  position: relative;
  margin: 2vh 0;

  img {
    height: 2.75rem;
    width: auto;
    margin: .5rem 1rem;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -4vh;
    right: -4vh;
    width: 4vh;
    height: 4vh;
    background-color: #01d0cf;
    z-index: -1;
  }


  .text-uppercase & {
    text-transform: uppercase;
  }
}
</style>
