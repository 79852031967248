<template>
  <div class="question">
    <v-row no-gutters>
      <v-col cols="12">
        <div class="d-flex align-center h-25">
          <div class="number flex-shrink-0">{{ question.question }}</div>
          <p>{{ question.title }}</p>
        </div>
        <p class="question-answers-title">ODPOWIEDZI</p>
        <ul>
          <li v-for="answer in question.answers" :key="answer.id" class="answer-list">
            <input type="radio" :id="'answer-' + answer.id" :name="'quiz-question-' + question.question"
              :checked="answerSelected === answer.id" @click="submitAnswer(answer.id)" class="radio-btn" />
            <label :for="'answer-' + answer.id">{{ answer.answer }}</label>
          </li>
        </ul>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: 'QuizQuestionComponent',
  props: {
    question: Object,
    answerSelected: Number,
  },
  methods: {
    submitAnswer(answerId) {
      this.$emit('answer', answerId);
    }
  },
};
</script>

<style lang="scss">
.question {
  width: 100%;
  padding: 0 4.5rem;

  .number {
    background-color: #00857c;
    color: white;
    text-align: center;
    width: 2.25rem;
    height: 2.25rem;
    line-height: 2rem;
    border-radius: 50%;
    box-shadow: -10px 5px 30px 0px rgba(0, 0, 0, 0.25);
    font-size: 1.1rem;
    padding-top: 0.1rem;

  }

  .number+p {
    max-width: 90%;
    margin-left: 2rem;
  }

  .question-answers-title {
    padding: 1rem 0rem .5rem 6rem;
  }

  .answer-list {
    list-style-type: none;
    line-height: 2rem;

  }

  .radio-btn {
    border: .125rem solid white;
    box-shadow: 0 0 0 5px #00857c;
    appearance: none;
    border-radius: 50%;
    width: .875rem;
    height: .875rem;
    margin: 0 1rem 0 7.5rem;
    background-color: #fff;
    transition: all ease-in 0.2s;
    vertical-align: middle;


    &:checked {
      background-color: #00857c;
    }

  }
}
</style>
