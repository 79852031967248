<template>
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12">
      <MedicalMenuHeader title="Czerniak" sub-title="Materiały Edukacyjne" imgPath="melanoma.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="data-area data-area-educational-materials d-flex flex-wrap align-center">
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/leczenie.png')" alt=""
        class="image-educational-materials-element"
        @click="firstDialogState = true; $refs.firstMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="firstDialogState" @input="firstDialogState = $event;"
        ref="firstMaterialComponent" :materials-data="firstMaterialsData"
        :active-slide-index-prop="firstMaterialsIndex"/>
    </v-col>
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/analiza.png')" alt=""
        class="image-educational-materials-element"
        @click="secondDialogState = true; $refs.secondMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="secondDialogState" @input="secondDialogState = $event"
        ref="secondMaterialComponent" :materials-data="secondMaterialsData"
        :active-slide-index-prop="secondMaterialsIndex" :debug="debug"/>
    </v-col>
    <v-col cols="6" class="align-self-start">
      <img :src="require('@/assets/img/educational-materials/schemat-postepowania.png')" alt=""
        class="image-educational-materials-element" @click="fifthDialogState = $event">
      <dialog-mailing :dialog-state="fifthDialogState" @input="fifthDialogState = $event"
        img-path="melanoma/5/czerniak-2023.png"/>
    </v-col>
    <v-col cols="12" align-self="end">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>


<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import ButtonMain from "@/components/Buttons/ButtonMainComponent";
import DialogMainSection from "@/components/Section/DialogMainSectionComponent";
import DialogMailing from "@/components/Section/DialogMailingComponent";

export default {
  name: 'MelanomaEducationalMaterialsView',
  components: {
    MedicalMenuHeader,
    ButtonMain,
    DialogMainSection,
    DialogMailing,
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  data() {
    return {
      debug: true,
      firstDialogState: false,
      secondDialogState: false,
      fifthDialogState: false,
      firstMaterialsIndex: 0,
      secondMaterialsIndex: 0,
      firstMaterialsData: {
        "menuInFooter": false,
        "class": 'melanoma-m1',
        "menu": [
          { "title": "Ryzyko nawrotu", "slides": [2] },
          { "title": "Projekt badania", "slides": [4] },
          { "title": "RFS", "slides": [5] },
          { "title": "DMFS", "slides": [6] },
          { "title": "PRFS-2", "slides": [7] },
          { "title": "Dawkowanie", "slides": [8] },
          { "title": "Jakość życia", "slides": [9] }
        ],
        "slides": [
          {
            "image": "melanoma/1/1.png",
            "popups": []
          },
          {
            "image": "melanoma/1/2.png",
            "popups": []
          },
          {
            "image": "melanoma/1/3.png",
            "popups": []
          },
          {
            "image": "melanoma/1/4.png",
            "popups": []
          },
          {
            "image": "melanoma/1/5.png",
            "popups": []
          },
          {
            "image": "melanoma/1/6.png",
            "popups": [
              {
                "cords": [2475, 200, 100],
                "shape": "circle",
                "closeCords": [2350, 10, 100],
                "closeShape": "circle",
                "image": "melanoma/1/6-1.png"
              }
            ]
          },
          {
            "image": "melanoma/1/7.png",
            "popups": [
              {
                "cords": [2475, 200, 100],
                "shape": "circle",
                "closeCords": [2350, 10, 100],
                "closeShape": "circle",
                "image": "melanoma/1/7-1.png"
              }
            ]
          },
          {
            "image": "melanoma/1/8.png",
            "popups": []
          },
          {
            "image": "melanoma/1/9.png",
            "popups": [
              {
                "cords": [2475, 450, 100],
                "shape": "circle",
                "closeCords": [2350, 10, 100],
                "closeShape": "circle",
                "image": "melanoma/1/9-2.png"
              }
            ]
          },
          {
            "image": "melanoma/1/10.png",
            "popups": []
          },
          {
            "image": "melanoma/1/11.png",
            "popups": []
          },
          {
            "image": "melanoma/1/12.png",
            "popups": []
          }
        ]
      },
      secondMaterialsData: {
        "menuInFooter": false,
        "class": 'melanoma-m2',
        "menu": [],
        "slides": [
          {
            "image": "melanoma/2/1.png",
            "popups": []
          },
          {
            "image": "melanoma/2/2.png",
            "popups": []
          },
          {
            "image": "melanoma/2/3.png",
            "popups": []
          },
          {
            "image": "melanoma/2/4.png",
            "popups": []
          },
          {
            "image": "melanoma/2/5.png",
            "popups": []
          },
          {
            "image": "melanoma/2/6.png",
            "popups": []
          },
          {
            "image": "melanoma/2/7.png",
            "popups": []
          },
          {
            "image": "melanoma/2/8.png",
            "popups": []
          },
          {
            "image": "melanoma/2/9.png",
            "popups": []
          },
          {
            "image": "melanoma/2/10.png",
            "popups": []
          },
          {
            "image": "melanoma/2/11.png",
            "popups": []
          },
          {
            "image": "melanoma/2/12.png",
            "popups": []
          },
          {
            "image": "melanoma/2/13.png",
            "popups": []
          }
        ]
      },
    }
  },
  created() {
    this.$emit('accept-number', 'PL-KEY-01067 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/melanoma-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss">
.melanoma-m1 {
  .dialog-menu {
    font-family: "Invention-Bold";
    font-size: 0.625rem;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    display: flex;
    align-items: center;

    div {
      padding: .625rem;
      border: 1px solid #ffffff;
      position: relative;
      width: 14.285%;

      &:first-child {
        background-color: #70ceb4;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #70ceb4;
          }
        }
      }

      &:nth-child(2) {
        background-color: #7fa9af;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #7fa9af;
          }
        }
      }

      &:nth-child(3) {
        background-color: #6cbf4a;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #6cbf4a;
          }
        }
      }

      &:nth-child(4) {
        background-color: #49a549;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #49a549;
          }
        }
      }

      &:nth-child(5) {
        background-color: #038733;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #038733;
          }
        }
      }

      &:nth-child(6) {
        background-color: #00531d;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #00531d;
          }
        }
      }

      &:nth-child(7) {
        background-color: #183800;
        border-top-right-radius: 0.5rem;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #183800;
          }
        }
      }

    }
  }
}

.melanoma-m3 {
  .dialog-menu {
    width: 100%;
    font-family: "Invention-Regular";
    font-size: 0.875rem;
    color: #ffffff;
    padding: .75rem;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
    background-color: rgba(123, 123, 123, 1);
    background-image: linear-gradient(180deg, rgba(123, 123, 123, 1) 0%, rgba(27, 27, 27, 1) 50%, rgba(0, 0, 0, 1) 100%);
    border-radius: 0 0 0 .5rem;
  }
}
</style>
