import CervicalCancerHomeView from "@/views/Medical/CervicalCancer/CervicalCancerHomeView";
import CervicalCancerEducationalMaterialsView from "@/views/Medical/CervicalCancer/CervicalCancerEducationalMaterialsView";
import CervicalCancerChplView from "@/views/Medical/CervicalCancer/CervicalCancerChplView";
import CervicalCancerDrugProgramView from "@/views/Medical/CervicalCancer/CervicalCancerDrugProgramView";

const routes = [
    {
        path: '/rak-szyjki-macicy',
        name: 'cervical_cancer',
        component: CervicalCancerHomeView,
    },
    {
        path: '/rak-szyjki-macicy/materialy-edukacyjne',
        name: 'cervical_cancer_educational_materials',
        component: CervicalCancerEducationalMaterialsView,
    },
    {
        path: '/rak-szyjki-macicy/program-lekowy',
        name: 'cervical_cancer_drug_program',
        component: CervicalCancerDrugProgramView,
    },
    {
        path: '/rak-szyjki-macicy/chpl',
        name: 'cervical_cancer_chpl',
        component: CervicalCancerChplView,
    },
];

export default routes;
