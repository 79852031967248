<template>
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12">
      <MedicalMenuHeader title="Potrójnie ujemny Rak Piersi" sub-title="Charakterystyka Produktu Leczniczego"
        imgPath="breast-cancer.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="data-area">
    <v-col cols="12">
      <pdf-viewer pdf-path="chpl.pdf" :pages="143" />
    </v-col>
    <v-col cols="12" align-self="end">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import PdfViewer from "@/components/Section/PdfViewerComponent";
import ButtonMain from "@/components/Buttons/ButtonMainComponent";

export default {
  name: 'BrestCancerChplView',
  components: {
    MedicalMenuHeader,
    PdfViewer,
    ButtonMain,
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01067 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/breast-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss"></style>
