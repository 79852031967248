<template>
  <div class="dialog-menu">
    <div v-for="menuElement in menuElements" :key="menuElement.title" @click="emitUpdateSlide(menuElement.slides[0])"
      v-bind:class="{ 'active': menuElement.slides.includes(this.activeSlideIndex) }">
      {{ menuElement.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogMenuComponent',
  emits: ['slide-index-update'],
  props: {
    menuElements: Object,
    activeSlideIndex: Number,
  },
  created() {
  },
  methods: {
    emitUpdateSlide(slideIndex) {
      this.$emit('slide-index-update', slideIndex);
    },
  }
}
</script>

<style lang="scss">
.dialog-menu {
  border-radius: 0 .5rem 0 0;
}
</style>
