<template>
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12">
      <MedicalMenuHeader title="Rak szyjki macicy" sub-title="Materiały Edukacyjne"
        imgPath="cervical-cancer.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="data-area data-area-educational-materials d-flex justify-center align-center">
    <v-col cols="9">
      <img :src="require('@/assets/img/educational-materials/cervical-cancer-m1.png')" alt=""
        class="image-educational-materials"
        @click="firstDialogState = true; $refs.firstMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="firstDialogState" @input="firstDialogState = $event"
        ref="firstMaterialComponent" :materials-data="firstMaterialsData"/>
    </v-col>
    <v-col cols="12" align-self="end">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import ButtonMain from "@/components/Buttons/ButtonMainComponent";
import DialogMainSection from "@/components/Section/DialogMainSectionComponent";

export default {
  name: 'CervicalCancerEducationalMaterialsView',
  components: {
    MedicalMenuHeader,
    ButtonMain,
    DialogMainSection,
  },
  data() {
    return {
      firstDialogState: false,
      firstMaterialsData: {
        "menuInFooter": false,
        "class": 'cervical-cancer-m1',
        "menu": [
          { "title": "Mechanizm działania", "slides": [1] },
          { "title": "Badanie PD-L1", "slides": [2] },
          { "title": "Badanie KN-826", "slides": [3, 4, 5] },
          { "title": "KN-826 OS", "slides": [6] },
          { "title": "KN-826 PFS", "slides": [7] },
          { "title": "KN-826 ORR i DOR", "slides": [8] },
          { "title": "Profil bezpieczeństwa", "slides": [9, 10, 11] },
          { "title": "Wnioski", "slides": [12, 13] }
        ],
        "slides": [
          {
            "image": "cervical-cancer/1/1.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/2.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/3.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/4.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/5.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/6.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/7.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/8.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/9.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/10.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/11.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/12.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/13.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/14.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/15.png",
            "popups": []
          },
          {
            "image": "cervical-cancer/1/16.png",
            "popups": []
          }
        ]
      },
    }
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01067 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/cervical-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss">
.cervical-cancer-m1  {
  background-color: rgba(124, 124, 124, 1);
  background-image: linear-gradient(180deg, rgba(124, 124, 124, 1) 0%, rgba(164, 165, 167, 1) 100%);

  .dialog-menu {
    font-family: "Invention-Bold";
    font-size: 0.625rem;
    color: #ffffff;
    background-color: rgba(124, 124, 124, 1);
    background-image: linear-gradient(180deg, rgba(124, 124, 124, 1) 0%, rgba(164, 165, 167, 1) 100%);
    width: 96%;
    display: flex;
    justify-content: space-between;

    div {
      position: relative;
      float: left;
      width: 10%;
      padding-left: 1.5%;
      padding-right: 1%;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      background-color: rgba(124, 124, 124, 1);
      background-image: linear-gradient(180deg, rgba(124, 124, 124, 1) 0%, rgba(164, 165, 167, 1) 100%);

      &::after {
        content: " ";
          width: 128%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -13%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.5rem;
          box-shadow: 19px -8px 15px 0 rgb(59 72 60 / 42%);
          background-color: rgba(124, 124, 124, 1);
          background-image: linear-gradient(180deg, rgba(124, 124, 124, 1) 0%, rgba(164, 165, 167, 1) 100%);
        }

        

      &:first-child {
        z-index: 8;
        padding-left: 2%;

        &::after {
          width: 100%;
          left: 15%;
          z-index: -8;
        }

      }

      &:nth-child(2) {
        z-index: 7;

        &::after {
          content: ' ';
          width: 122%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -10%;
          z-index: -7;
        }
      }

      &:nth-child(3) {
        z-index: 6;

        &::after {
          content: ' ';

          z-index: -6;
        }
      }

      &:nth-child(4) {
        z-index: 5;
        padding: 0 1% 0% 2.5%;

        &::after {
          content: ' ';
          z-index: -5;
        }
      }

      &:nth-child(5) {
        z-index: 4;
        padding: 0 1% 0% 2.5%;

        &::after {
          content: ' ';
          z-index: -4;
        }
      }

      &:nth-child(6) {
        z-index: 3;

        &::after {
          content: ' ';
          z-index: -3;
        }
      }

      &:nth-child(7) {
        z-index: 2;
        width: 12%;

        &::after {
          content: ' ';
          z-index: -2;
        }
      }

      &:nth-child(8) {
        z-index: 1;
        width: 11%;

        &::after {
          content: ' ';
          z-index: -1;
        }
      }


      &.active {
        background-color: rgba(116, 163, 44, 1);
        background-image: linear-gradient(180deg, rgba(116, 163, 44, 1) 0%, rgba(139, 178, 55, 1) 100%);

        &::after {
          background-color: rgba(116, 163, 44, 1);
          background-image: linear-gradient(180deg, rgba(116, 163, 44, 1) 0%, rgba(139, 178, 55, 1) 100%);
        }
      }

    }
  }
}
</style>
