import MelanomaHomeView from '@/views/Medical/Melanoma/MelanomaHomeView'
import MelanomaEducationalMaterialsView from "@/views/Medical/Melanoma/MelanomaEducationalMaterialsView";
import MelanomaVideoView from "@/views/Medical/Melanoma/MelanomaVideoView";
import MelanomaChplView from "@/views/Medical/Melanoma/MelanomaChplView";


const routes = [
    {
        path: '/czerniak',
        name: 'melanoma',
        component: MelanomaHomeView
    },
    {
        path: '/czerniak/materialy-edukacyjne',
        name: 'melanoma_educational_materials',
        component: MelanomaEducationalMaterialsView
    },
    {
        path: '/czerniak/video',
        name: 'melanoma_video',
        component: MelanomaVideoView
    },
    {
        path: '/czerniak/chpl',
        name: 'melanoma_chpl',
        component: MelanomaChplView
    },
];

export default routes;
