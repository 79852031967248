<template>
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12">
      <MedicalMenuHeader title="Niedrobnokomórkowy Rak Płuca" sub-title="Materiały Edukacyjne"
        imgPath="lung-cancer.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="data-area data-area-educational-materials d-flex justify-center align-center">
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/lung-cancer-1.png')" alt=""
        class="image-educational-materials-element" 
        @click="firstDialogState = true; $refs.firstMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="firstDialogState" @input="firstDialogState = $event"
        ref="firstMaterialComponent" :materials-data="firstMaterialsData" />
    </v-col>
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/lung-cancer-2.png')" alt=""
        class="image-educational-materials-element" 
        @click="secondDialogState = true; $refs.secondMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="secondDialogState" @input="secondDialogState = $event"
        ref="secondMaterialComponent" :materials-data="secondMaterialsData"/>
    </v-col>
    <v-col cols="12" align-self="end">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import ButtonMain from "@/components/Buttons/ButtonMainComponent";
import DialogMainSection from "@/components/Section/DialogMainSectionComponent";


export default {
  name: 'LungCancerEducationalMaterialsView',
  components: {
    MedicalMenuHeader,
    ButtonMain,
    DialogMainSection,

  },
  data() {
    return {
      firstDialogState: false,
      secondDialogState: false,
      firstMaterialsData: {
        "menuInFooter": false,
        "class": 'lung-cancer-m1',
        "menu": [
          { "title": "OS", "slides": [2] },
          { "title": "PFS", "slides": [3] },
          { "title": "ORR i DOR", "slides": [4] },
          { "title": "Bezpieczeństwo", "slides": [6, 7, 8, 9] },
          { "title": "Opis Badań", "slides": [10] },
          { "title": "Wytyczne i ranking terapii", "slides": [11, 12, 13] }
        ],
        "slides": [
          {
            "image": "lung-cancer/1/1.png",
            "popups": []
          },
          {
            "image": "lung-cancer/1/2.png",
            "popups": []
          },
          {
            "image": "lung-cancer/1/3.png",
            "popups": [
              {
                "cords": [850, 430, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/3-1.png"
              },
              {
                "cords": [1680, 430, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/3-2.png"
              },
              {
                "cords": [2510, 430, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/3-3.png"
              },
              {
                "cords": [1680, 1560, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/3-4.png"
              },
              {
                "cords": [2510, 1560, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/3-5.png"
              },
            ]
          },
          {
            "image": "lung-cancer/1/4.png",
            "popups": [
              {
                "cords": [850, 430, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/4-1.png"
              },
              {
                "cords": [1680, 430, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/4-2.png"
              },
              {
                "cords": [2510, 430, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/4-3.png"
              },
              {
                "cords": [1680, 1545, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/4-4.png"
              },
              {
                "cords": [2510, 1545, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/4-5.png"
              },
            ]
          },
          {
            "image": "lung-cancer/1/5.png",
            "popups": [
              {
                "cords": [1680, 520, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/5-1.png"
              },
              {
                "cords": [2510, 520, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/5-2.png"
              }
            ]
          },
          {
            "image": "lung-cancer/1/6.png",
            "popups": []
          },
          {
            "image": "lung-cancer/1/7.png",
            "popups": [
              {
                "cords": [1020, 650, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/7-1.png"
              }
            ]
          },
          {
            "image": "lung-cancer/1/8.png",
            "popups": [
              {
                "cords": [1020, 755, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/8-1.png"
              }
            ]
          },
          {
            "image": "lung-cancer/1/9.png",
            "popups": [
              {
                "cords": [1020, 620, 100],
                "shape": "circle",
                "closeCords": [2370, 10, 100],
                "closeShape": "circle",
                "image": "lung-cancer/1/9-1.png"
              }
            ]
          },
          {
            "image": "lung-cancer/1/10.png",
            "popups": []
          },
          {
            "image": "lung-cancer/1/11.png",
            "popups": []
          },
          {
            "image": "lung-cancer/1/12.png",
            "popups": []
          },
          {
            "image": "lung-cancer/1/13.png",
            "popups": []
          },
          {
            "image": "lung-cancer/1/14.png",
            "popups": []
          },
          {
            "image": "lung-cancer/1/15.png",
            "popups": []
          }
        ]
      },
      secondMaterialsData: {
        "menuInFooter": false,
        "class": 'lung-cancer-m2',
        "menu": [
          { "title": "Opis Badania", "slides": [2, 9] },
          { "title": "OS", "slides": [3, 10] },
          { "title": "PFS", "slides": [4, 11] },
          { "title": "ORR", "slides": [5, 12] },
          { "title": "Dawkowanie", "slides": [6] },
          { "title": "Bezpieczeństwo", "slides": [7, 13] }
        ],
        "slides": [
          {
            "image": "lung-cancer/2/1.png",
            "popups": []
          },
          {
            "image": "lung-cancer/2/2.png",
            "popups": []
          },
          {
            "image": "lung-cancer/2/3.png",
            "popups": [
              {
                "cords": [2310,175,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/3-1.png"
              }
            ]
          },
          {
            "image": "lung-cancer/2/4.png",
            "popups": []
          },
          {
            "image": "lung-cancer/2/5.png",
            "popups": [
              {
                "cords": [2200, 1200, 100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/5-1.png"
              }
            ]
          },
          {
            "image": "lung-cancer/2/6.png",
            "popups": [
              {
                "cords": [2310,175,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/6-1.png"
              }
            ]
          },
          {
            "image": "lung-cancer/2/7.png",
            "popups": []
          },
          {
            "image": "lung-cancer/2/8.png",
            "popups": [
              {
                "cords": [2310,175,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/8-1.png"
              }
            ]
          },
          {
            "image": "lung-cancer/2/9.png",
            "popups": []
          },
          {
            "image": "lung-cancer/2/10.png",
            "popups": [
              {
                "cords": [2310,175,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/10-1.png"
              },
              {
                "cords": [270,1250,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/10-2.png"
              }
            ]
          },
          {
            "image": "lung-cancer/2/11.png",
            "popups": [
            {
                "cords": [1230,630,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/11-1.png"
              },
              {
                "cords": [2340,630,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/11-2.png"
              }
            ]
          },
          {
            "image": "lung-cancer/2/12.png",
            "popups": [
              {
                "cords": [1230,630,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/12-1.png"
              },
              {
                "cords": [2340,630,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/12-2.png"
              }
            ]
          },
          {
            "image": "lung-cancer/2/13.png",
            "popups": []
          },
          {
            "image": "lung-cancer/2/14.png",
            "popups": [
              {
                "cords": [2310,175,100],
                "shape": "circle",
                "closeCords": [2220, 40, 100],
                "closeShape": "circle",
                "image": "lung-cancer/2/14-1.png"
              }
            ]
          },
          {
            "image": "lung-cancer/2/15.png",
            "popups": []
          },
          {
            "image": "lung-cancer/2/16.png",
            "popups": []
          },
          {
            "image": "lung-cancer/2/17.png",
            "popups": []
          }
        ]
      },
    }
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01067 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/lung-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss">
.lung-cancer-m1  {
  background-color: #f4faf6;

  .dialog-menu {
    font-family: "Invention-Bold";
    font-size: 0.625rem;
    color: #ffffff;
    padding-right: 2%;

    div {
      position: relative;
      float: left;
      width: 16.65%;
      padding-left: 1rem;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      z-index: 1;

      &:first-child {
        background-color: #81aab0;
        z-index: 6;
        border-bottom-right-radius: 2rem 100%;
        margin: 0;
        padding-left: .5rem;

        &::after {
          content: ' ';
          width: 80%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 15px -3px 15px 0 rgba(76, 135, 144, 0.55);
          z-index: -6;
          background: #81aab0;
        }

      }

      &:nth-child(2) {
        background-color: #8bb1b4;
        z-index: 5;
        padding-left: .5rem;


        &::after {
          content: ' ';
          width: 117%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -10%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 15px -3px 15px 0 rgba(76, 135, 144, 0.55);
          z-index: -5;
          background: #8bb1b4;
        }
      }

      &:nth-child(3) {
        background-color: #98b9be;
        z-index: 4;

        &::after {
          content: ' ';
          width: 115%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -5%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 15px -3px 15px 0 rgba(76, 135, 144, 0.55);
          z-index: -4;
          background: #98b9be;
        }
      }

      &:nth-child(4) {
        background-color: #a5c2c6;
        z-index: 3;
        padding-left: 1.75rem;

        &::after {
          content: ' ';
          width: 115%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -5%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 15px -3px 15px 0 rgba(76, 135, 144, 0.55);
          z-index: -3;
          background: #a5c2c6;
        }
      }

      &:nth-child(5) {
        background-color: #b2cbcf;
        z-index: 2;

        &::after {
          content: ' ';
          width: 115%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -5%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 15px -3px 15px 0 rgba(76, 135, 144, 0.55);
          z-index: -2;
          background: #b2cbcf;
        }
      }

      &:nth-child(6) {
        background-color: #bfd4d7;
        z-index: 1;
        border-bottom-right-radius: 100%;
        padding: 0 1rem 0 2rem;

        &::after {
          content: ' ';
          width: 115%;
          height: 100%;
          position: absolute;
          top: 0;
          right: -3%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          border-top-right-radius: 0.5rem;
          box-shadow: 15px -3px 15px 0 rgba(76, 135, 144, 0.55);
          z-index: -1;
          background: #bfd4d7;
        }

      }


      &.active {
        background-color: #55a4b3;

        &::after {
          background-color: #55a4b3;
        }
      }

    }
  }
}

.lung-cancer-m2  {
  background-color: #ffffff;

  .dialog-menu {
    font-family: "Invention-Bold";
    font-size: 0.625rem;
    color: #ffffff;
    padding-right: 2%;

    div {
      position: relative;
      float: left;
      width: 16.65%;
      padding-left: 1rem;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      z-index: 1;

      &:first-child {
        background-color: #58585a;
        z-index: 6;
        border-bottom-right-radius: 2rem 100%;
        margin: 0;
        padding-left: .5rem;

        &::after {
          content: ' ';
          width: 80%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 8px -1px 7px 0px rgba(81, 86, 86, 0.55);
          z-index: -6;
          background: #58585a;
        }

      }

      &:nth-child(2) {
        background-color: #69696b;
        z-index: 5;
        padding-left: .5rem;


        &::after {
          content: ' ';
          width: 117%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -10%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 8px -1px 7px 0px rgba(81, 86, 86, 0.55);
          z-index: -5;
          background: #69696b;
        }
      }

      &:nth-child(3) {
        background-color: #9b9b9d;
        z-index: 4;

        &::after {
          content: ' ';
          width: 115%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -5%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 8px -1px 7px 0px rgba(81, 86, 86, 0.55);
          z-index: -4;
          background: #9b9b9d;
        }
      }

      &:nth-child(4) {
        background-color: #acacac;
        z-index: 3;
        padding-left: 1.75rem;

        &::after {
          content: ' ';
          width: 115%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -5%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 8px -1px 7px 0px rgba(81, 86, 86, 0.55);
          z-index: -3;
          background: #acacac;
        }
      }

      &:nth-child(5) {
        background-color: #acacac;
        z-index: 2;

        &::after {
          content: ' ';
          width: 115%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -5%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          box-shadow: 8px -1px 7px 0px rgba(81, 86, 86, 0.55);
          z-index: -2;
          background: #acacac;
        }
      }

      &:nth-child(6) {
        background-color: #b7b7b7;
        z-index: 1;
        border-bottom-right-radius: 100%;
        padding: 0 1rem 0 2rem;

        &::after {
          content: ' ';
          width: 115%;
          height: 100%;
          position: absolute;
          top: 0;
          right: -3%;
          transform: skew(-28deg);
          border-bottom-right-radius: 0.25rem;
          border-top-right-radius: 0.5rem;
          box-shadow: 9px -2px 13px 0px rgba(81, 86, 86, 0.55);
          z-index: -1;
          background: #b7b7b7;
        }

      }


      &.active {
        background-color: #55a4b3;

        &::after {
          background-color: #55a4b3;
        }
      }

    }
  }
}
</style>
