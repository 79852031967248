<template>
  <v-dialog width="auto" v-model="subDialogState">
    <v-col cols="6" class="sub-dialog-content">
      <div class="sub-dialog-content__image">
        <img :src="require('@/assets/img/educational-materials/' + subDialogData.image)"
          :usemap="'#map-' + activeSlideIndex + '-' + subDialogIndex" ref="subDialogContentImage" />
        <div v-if="debug">
          <div class="sub-dialog-content__area-shape" :style="{
            left: Math.ceil((subDialogData.closeCords[0] - subDialogData.closeCords[2]) * scaleRatio) + 'px',
            top: Math.ceil((subDialogData.closeCords[1]) * scaleRatio) + 'px',
            width: Math.ceil((subDialogData.closeCords[2] * 2) * scaleRatio) + 'px',
            height: Math.ceil((subDialogData.closeCords[2] * 2) * scaleRatio) + 'px',
            borderRadius: '50%',
          }">
          </div>
        </div>
        <map :name="'map-' + activeSlideIndex + '-' + subDialogIndex">
          <area :shape="subDialogData.closeShape"
            :coords="Math.ceil(subDialogData.closeCords[0] * scaleRatio) + ',' + Math.ceil(subDialogData.closeCords[1] * scaleRatio) + ',' + Math.ceil(subDialogData.closeCords[2] * scaleRatio)"
            @click="closeSubDialog" />
        </map>
      </div>
    </v-col>
  </v-dialog>
</template>

<script>

export default {
  name: 'SubDialogComponent',
  methods: {
    closeSubDialog() {
      this.$emit('close-sub-dialog');
    },
    manipulateOverlay() {
      setTimeout(() => {
        const elements = document.querySelectorAll('.v-overlay__content');
        if (elements.length >= 2) {
          elements[1].style.backgroundImage = 'none';
        }
      }, 10);
    },
    calculateScaleRatio() {
      setTimeout(() => {
        const imageElement = this.$refs.subDialogContentImage;
        if (imageElement) {
          const naturalWidth = imageElement.naturalWidth;
          const currentWidth = imageElement.clientWidth;
          this.scaleRatio = (currentWidth / naturalWidth);
        }
      }, 100);
    },
  },
  emits: ['input', 'close-sub-dialog'],
  props: {
    dialogState: Boolean,
    subDialogData: Object,
    debug: Boolean,
    activeSlideIndex: Number,
    subDialogIndex: Number,
  },
  computed: {
    subDialogState: {
      get() {
        this.manipulateOverlay();
        this.calculateScaleRatio();
        return this.dialogState;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  data() {
    return {
      scaleRatio: 0,
    }
  },
}
</script>

<style lang="scss">
.sub-dialog-content {
  position: relative;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0 !important;
  background-color: transparent;
  border-radius: 0 .85rem 0 .85rem;

  &__image {
    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 0 .85rem 0 .85rem;
    }
  }

  &__area-shape {
    position: absolute;
    border: 1px solid red;
    opacity: 0.5;
    pointer-events: none;
    z-index: 1000;
  }
}
</style>
