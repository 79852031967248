<template>
  <v-row no-gutters class="content-box-main d-flex flex-wrap align-content-space-around">
    <v-col cols="6">
      <MenuTile title="Niedrobnokomórkowy <br> Rak Płuca" urlPath="lung_cancer" imgPath="lung-cancer.png" />
    </v-col>
    <v-col cols="6">
      <MenuTile title="Potrójnie Ujemny <br> Rak Piersi" urlPath="brest_cancer" imgPath="breast-cancer.png" />
    </v-col>
    <v-col cols="6">
      <MenuTile title="Czerniak" urlPath="melanoma" imgPath="melanoma.png" />
    </v-col>
    <v-col cols="6">
      <MenuTile title="Nowotwory <br> Głowy i Szyi" urlPath="head_and_neck_cancer" imgPath="head-and-neck-cancers.png" />
    </v-col>
    <v-col cols="6">
      <MenuTile title="Rak <br> nerkowokomórkowy" urlPath="renal_cell_carcinoma" imgPath="renal-cell-carcinoma-cancer.png" />
    </v-col>
    <v-col cols="6">
      <MenuTile title="Rak Szyjki Macicy" urlPath="cervical_cancer" imgPath="cervical-cancer.png" />
    </v-col>

    <v-col cols="12" class="menu-tile-quizBtn">
      <MenuTile title="Sprawdź swoją<br> wiedzę na temat<br> keytruda®" urlPath="quiz" imgPath="quiz-ico.png" :inversed="true" />
    </v-col>
  </v-row>
</template>

<script>
import MenuTile from '@/components/Blocks/MenuTileComponent'

export default {
  name: 'HomeView',
  components: {
    MenuTile
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01066 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', true);
    this.$emit('person-path', require('@/assets/img/main/main-menu-person.png'));
  },
}
</script>

<style lang="scss"></style>
