<template>
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12">
      <MedicalMenuHeader title="Nowotwory Głowy i Szyi" sub-title="Materiały Video" imgPath="head-and-neck-cancers.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="data-area data-area-video">
    <v-col cols="12">
      <multi-video-component ref="multiVideo" :videos="videos" />
      <single-video video-url="https://www.youtube.com/embed/AKOCehAEtTo"
        title="Jak działa Keytruda (pembrolizumab)? Mechanizm działania leku Keytruda w monoterapii:" />
    </v-col>
    <v-col cols="12" align-self="end">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import ButtonMain from "@/components/Buttons/ButtonMainComponent";
import MultiVideoComponent from "@/components/Section/MultiVideoComponent.vue";

export default {
  name: 'HeadAndNeckCancerVideoView',
  components: {
    MultiVideoComponent,
    MedicalMenuHeader,
    ButtonMain,
  },
  data() {
    return {
      videos: [
        {
          'id': 0,
          'url': 'https://www.youtube.com/embed/AKOCehAEtTo',
          'text': 'Jak działa Keytruda (pembrolizumab)? Mechanizm działania leku Keytruda w monoterapii:',
          'image': require('@/assets/img/icons/play-ico-blue.png'),
        }, {
          'id': 1,
          'url': 'https://www.youtube.com/embed/P9k8cgr7gsw',
          'text': 'Keytruda w leczeniu nowotworów głowy i szyi',
          'image': require('@/assets/img/icons/play-ico-blue.png'),
        }
      ],
    }
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01066 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/head-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss"></style>
