<template>
  <router-link :to="{ name: urlPath }">
    <div class="menu-tile-component d-flex justify-center align-center" :class="[{'menu-tile-component__inversed': inversed }]">
      <img :src="require('@/assets/img/medical/' + imgPath)" />
      <p v-html="title"></p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'MenuTile',
  props: {
    title: String,
    urlPath: String,
    imgPath: String,
    inversed: Boolean,
  },
}
</script>

<style lang="scss">
.menu-tile-component {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  background-image: linear-gradient(190deg, rgba(255, 255, 255, 1) 46%, rgba(229, 239, 238, 1) 100%);
  box-shadow: 0 0 1rem 0 rgba(61, 61, 64, 1);
  border-radius: 0 1rem;
  padding: 0 1em 0 5em;
  font-family: 'Invention-Bold', sans-serif;
  font-size: .875rem;
  line-height: 1rem;
  text-transform: uppercase;
  width: 78%;
  height: 7.75em;

  p {
    width: 100%;
    vertical-align: middle;
  }

  img {
    width: 8em;
    position: absolute;
    left: -2.75em;
    top: 50%;                       
    transform: translate(0, -50%);

  }

  &::before {
    content: '';
    position: absolute;
    top: -5%;
    right: -3%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6rem 4rem 0px;
    border-color: transparent #01d0cf transparent transparent;
    border-radius: 0 1rem 0 0;
    z-index: -1;
  }


  &__inversed {
    background-color: #00857C;
    background-image: linear-gradient(190deg, #00857C 46%, #00857C 100%);
    color: white;

    &::before {
      border-color: transparent #eaf1f1 transparent transparent;
    }
  }
}

.menu-tile-quizBtn {
  .menu-tile-component {
    padding: 0 1em 0 3.5em;
    max-height: 6.5em;
    width: 46%;
    margin: 0 auto;
  }
}

</style>

