<template>
  <v-row no-gutters class="home-category">
    <v-col cols="12">
      <MedicalMenuHeader title="Czerniak" imgPath="melanoma.png" />
    </v-col>
    <v-col cols="6" class="text-uppercase">
      <MedicalMenuTile title="Materiały Edukacyjne" urlPath="melanoma_educational_materials"
        imgPath="educational-materials-ico.png" />
    </v-col>
    <v-col cols="6" class="text-uppercase">
      <MedicalMenuTile title="Materiały Video" urlPath="melanoma_video" imgPath="video-materials-ico.png" />
    </v-col>
    <v-col cols="6">
      <MedicalMenuTile title="ChPL" urlPath="melanoma_chpl" imgPath="chpl-ico.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="home-category">
    <v-col cols="auto" align-self="end">
      <div @click="goBack">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuTile from '@/components/Blocks/MedicalMenuTileComponent'
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import ButtonMain from "@/components/Buttons/ButtonMainComponent";

export default {
  name: 'MelanomaHomeView',
  components: {
    MedicalMenuTile,
    MedicalMenuHeader,
    ButtonMain,
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01066 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/melanoma-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss"></style>
