<template>
  <div class="medical-menu-header-component">
    <img :src="require('@/assets/img/medical/' + imgPath)" />
    <div>
      <p>{{ title }}</p>
      <p class="header-subtitle" v-if="subTitleProp">{{ subTitleProp }} <span v-if="extraSubTitleProp"
          class="header-subtitle-extra">{{
            extraSubTitleProp }}</span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicalMenuHeader',
  props: {
    title: String,
    subTitle: String,
    extraSubTitle: String,
    imgPath: String,
  },
  data() {
    return {
      subTitleProp: '',
      extraSubTitleProp: '',
    }
  },
  created() {
    if (this.subTitle) {
      this.subTitleProp = this.subTitle;
    }
    if (this.extraSubTitle) {
      this.extraSubTitleProp = this.extraSubTitle;
    }
  },
}
</script>

<style lang="scss">
.medical-menu-header-component {
  background-color: rgba(255, 255, 255, 1);
  background-image: linear-gradient(190deg, rgba(255, 255, 255, 1) 46%, rgba(229, 239, 238, 1) 100%);
  box-shadow: 0 0 1rem 0 rgba(61, 61, 64, 1);
  border-radius: 0 1rem;
  padding: .75rem .5rem .5rem 5rem;
  margin: 1rem 0;
  font-family: 'Invention-Regular', sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  position: relative;

  .header-subtitle {
    font-family: 'Invention-Bold', sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .header-subtitle-extra {
    color: #01c7c5;
  }

  img {
    position: absolute;
    width: auto;
    height: 6rem;
    left: 1.5rem;
    top: 50%;
    transform: translate(0, -50%);
  }

  .home-category & {
    margin-left: -4%;
    padding: 2rem .5rem 2rem 9rem;

    img {
      height: 8rem;
    }
  }

  .home-category-submenu & {
    margin-left: -4%;
  }

  .quiz & {
    padding: .5rem .5rem 0 1rem;

    p {
      padding-right: 6rem;
      line-height: 4.5rem;
      margin: 0 auto;
    }

    img {
      width: auto;
      height: 4.5rem;
      margin: 0 1rem 0 0;
      position: static;
      float: left;
      transform: none;
    }

  }


  .live-stream & {
    padding: .5rem .5rem 0 1rem;

    p {
      padding-right: 6rem;
      line-height: 4.5rem;
      margin: 0 auto;
    }

    img {
      width: auto;
      height: 4.5rem;
      margin: 0 1rem 0 0;
      position: static;
      float: left;
      transform: none;
    }

  }




  &::before {
    content: '';
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5rem 4rem 0px;
    border-color: transparent #01d0cf transparent transparent;
    border-radius: 0 1rem 0 0;
    z-index: -1;
  }


}
</style>
