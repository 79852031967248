<template>
  <v-dialog v-model="internalDialogState">
    <!-- <div class="dialog-mailing-logo">
      <img :src="require('@/assets/img/main/keytruda-logo.png')" />
    </div> -->
    <v-col cols="8" class="dialog-mailing pa-0">
      <div class="dialog-mailing__content">
        <img :src="require('@/assets/img/educational-materials/' + imgPath)" />
      </div>
      <div class="dialog-close" @click="internalDialogState = false">
        <img :src="require('@/assets/img/icons/close-material.png')" />
      </div>
    </v-col>
    <div v-if="qrCodeImagePath" class="dialog-mailing-qr-code">
      <img :src="require('@/assets/img/educational-materials/' + qrCodeImagePath)" />
      <p>Pobierz</p>
    </div>
  </v-dialog>
</template>

<script>

export default {
  name: 'DialogMailingComponent',
  components: {
  },
  props: {
    dialogState: Boolean,
    qrCodeImagePathProp: String,
    imgPath: String,
  },
  emits: ['input'],
  created() {
    if (this.qrCodeImagePathProp) {
      this.qrCodeImagePath = this.qrCodeImagePathProp;
    }
  },
  data() {
    return {
      qrCodeImagePath: null,
    }
  },
  methods: {

  },
  computed: {
    internalDialogState: {
      get() {
        return this.dialogState;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
}
</script>

<style lang="scss">
.dialog-mailing {
  position: relative;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 0 .5rem 0 .5rem;
  -webkit-box-shadow: 0px 0px 40px 10px rgba(3, 100, 94, 0.28);
  -moz-box-shadow: 0px 0px 40px 10px rgba(3, 100, 94, 0.28);
  box-shadow: 0px 0px 40px 10px rgba(3, 100, 94, 0.28);

  &::before {
    content: '';
    position: absolute;
    bottom: -1.5rem;
    right: -1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #01d0cf;
    z-index: -1;
  }


  &__content {
    max-height: 85vh;
    overflow: auto;
    border-radius: 0 .5rem 0 .5rem;

    img {
      width: 100%;
      height: auto;
    }
  }
}


.dialog-mailing-close {
  position: absolute;
  right: -2.5rem;
  top: 5%;
  background-color: #00827a;
  z-index: -1;
  padding: .75rem;

  img {
    display: block;
    width: 1rem;
    height: auto;
  }
}
</style>
