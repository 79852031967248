<template>
  <div class="w-100 d-flex justify-space-between align-center">
    <arrow-round @action="previousVideo" :is-left="true" />
    <div class="slider-video-player">
      <iframe width="640" height="360" :key="currentVideoIndex" :src="currentVideo.url + '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&cc_load_policy=0'" title="YouTube video player"
        frameborder="0" allow="accelerometer; autoplay;  picture-in-picture; web-share"></iframe>
    </div>
    <arrow-round @action="nextVideo" :is-left="false" />
  </div>
</template>

<script>
import ArrowRound from "@/components/Buttons/ArrowRoundButtonComponent";

export default {
  name: 'SliderVideo',
  components: {
    ArrowRound,
  },
  data() {
    return {
      currentVideo: null,
      currentVideoIndex: null,
    }
  },
  props: {
    videos: Object,
    videoIndex: Number,
  },
  created() {
    this.currentVideoIndex = this.videoIndex;
    this.resolveCurrentVideo();
  },
  methods: {
    nextVideo() {
      if (this.currentVideoIndex < this.videos.length - 1) {
        this.currentVideoIndex++;
      } else {
        this.currentVideoIndex = 0;
      }
      this.resolveCurrentVideo();
    },
    previousVideo() {
      if (this.currentVideoIndex <= 0) {
        this.currentVideoIndex = this.videos.length - 1;
      } else {
        this.currentVideoIndex--;
      }
      this.resolveCurrentVideo();
    },

    resolveCurrentVideo() {
      this.currentVideo = this.videos[this.currentVideoIndex % this.videos.length];
    },
  }
}
</script>

<style lang="scss">
.slider-video-player {
  position: relative;
  width: 27rem;
  height: 15.19rem;
  margin: 0 auto;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
