<template>
  <div class="d-flex flex-wrap align-center justify-space-between" :class="{ 'footer-outline': !homepageFooter }">
    <hr class="footer-separator">
    <div class="d-flex">
      <div v-if="homepageFooter">
        <button-main title="Live stream" url-path="live_stream" img-path="livestream-ico.png" />
      </div>
      <div v-if="homepageFooter">
        <a href="https://clinicaltrials.gov/" target="_blank">
          <button-main title="Badania kliniczne" img-path="clinical-tests-ico.png" />
        </a>
      </div>
      <div v-if="homepageFooter">
        <a href="https://www.msd.pl/" target="_blank">
          <button-main title="Strona MSD" img-path="msd-page-ico.png" />
        </a>
      </div>
      <div v-if="!homepageFooter">
        <button-outline title="Niedrobnokomórkowy<br>rak płuca" url-path="lung_cancer" />
      </div>
      <div v-if="!homepageFooter">
        <button-outline title="Potrójnie ujemny<br>rak piersi" url-path="brest_cancer" />
      </div>
      <div v-if="!homepageFooter">
        <button-outline title="Czerniak" url-path="melanoma" />
      </div>
      <div v-if="!homepageFooter">
        <button-outline title="Nowotwory<br>głowy i szyi" url-path="head_and_neck_cancer" />
      </div>
      <div v-if="!homepageFooter">
        <button-outline title="Rak<br>nerkowokomórkowy" url-path="renal_cell_carcinoma" />
      </div>
      <div v-if="!homepageFooter">
        <button-outline title="Rak<br>szyjki macicy" url-path="cervical_cancer" />
      </div>
      <div :class="{ 'hidden': !homepageFooter }">
        <button class="button-main-component" @click="triggerSurvey">Oceń aplikację</button>
      </div>
    </div>
    <div class="footer-logo">
      <router-link :to="{ name: 'home' }">
        <img :src="footerLogoPath" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ButtonMain from "@/components/Buttons/ButtonMainComponent";
import ButtonOutline from "@/components/Buttons/ButtonOutlineComponent";

export default {
  name: 'FooterMenuSection',
  props: {
    homepageFooter: Boolean
  },
  components: {
    ButtonOutline,
    ButtonMain,
  },
  emits: ['survey-loader'],
  methods: {
    triggerSurvey() {
      this.$emit('survey-loader', 1);
    },
  },
  data() {
    return {
      footerLogoPath: require('@/assets/img/main/msd-logo.png'),
    };
  },
}
</script>

<style lang="scss">
.hidden {
  display: none;
}
</style>
