import HeadAndNeckCancerHomeView from "@/views/Medical/HeadAndNeckCancer/HeadAndNeckCancerHomeView";
import HeadAndNeckCancerVideoView from "@/views/Medical/HeadAndNeckCancer/HeadAndNeckCancerVideoView";
import HeadAndNeckCancerChplView from "@/views/Medical/HeadAndNeckCancer/HeadAndNeckCancerChplView";
import HeadAndNeckCancerEducationalMaterialsView from "@/views/Medical/HeadAndNeckCancer/HeadAndNeckCancerEducationalMaterialsView";

const routes = [
    {
        path: '/nowotwory-glowy-i-szyi',
        name: 'head_and_neck_cancer',
        component: HeadAndNeckCancerHomeView
    },
    {
        path: '/nowotwory-glowy-i-szyi/video',
        name: 'head_and_neck_cancer_video',
        component: HeadAndNeckCancerVideoView
    },
    {
        path: '/nowotwory-glowy-i-szyi/chpl',
        name: 'head_and_neck_cancer_chpl',
        component: HeadAndNeckCancerChplView
    },
    {
        path: '/nowotwory-glowy-i-szyi/materialy-edukacyjne',
        name: 'head_and_neck_cancer_educational_materials',
        component: HeadAndNeckCancerEducationalMaterialsView
    },
];

export default routes;
