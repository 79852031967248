<template>
  <div class="arrow-round-button" v-if="isLeft" @click="emitAction">
    <img :src="require('@/assets/img/icons/button-arrow-left.png')">
  </div>
  <div class="arrow-round-button" v-if="!isLeft" @click="emitAction">
    <img :src="require('@/assets/img/icons/button-arrow-right.png')">
  </div>
</template>

<script>
export default {
  name: 'ArrowRound',
  props: {
    isLeft: Boolean,
  },
  methods: {
    emitAction() {
      this.$emit('action');
    },
  }
}
</script>

<style lang="scss">
.arrow-round-button {

  img {
    width: 3rem;
    height: auto;
    border: 1px solid #00857c;
    border-radius: 50%;
    background-color: #ffffff;
    padding: 1rem;
  }
}
</style>
