<template>
  <router-link v-if="urlPath" :to="{ name: urlPath }">
    <div class="button-main-component" v-bind:class="{ 'disabled': isDisabled }">
      <img v-if="leftIcon" :src="require('@/assets/img/main/' + imgPath)">
      {{ title }}
      <img v-if="!leftIcon" :src="require('@/assets/img/main/' + imgPath)">
    </div>
  </router-link>
  <div v-if="!urlPath" class="button-main-component" v-bind:class="{ 'disabled': isDisabled }">
    <img v-if="leftIcon" :src="require('@/assets/img/main/' + imgPath)">
    {{ title }}
    <img v-if="!leftIcon" :src="require('@/assets/img/main/' + imgPath)">
  </div>
</template>

<script>
export default {
  name: 'ButtonMain',
  props: {
    title: String,
    urlPath: String,
    imgPath: String,
    leftIcon: Boolean,
    isDisabled: Boolean,
  },
}
</script>

<style lang="scss">
.button-main-component {
  display: flex;
  align-items: center;
  font-size: .925rem;
  font-family: "Invention-Regular";
  padding: .5rem 1.25rem;
  background-color: #00857c;
  color: #ffffff;
  border: 1px solid #b9c9db;

  img {
    width: auto;
    height: 2rem;
    margin-left: 1.25rem;
  }
}


.back-button {
  .button-main-component {
    display: flex;
    align-items: center;
    font-size: .925rem;
    font-family: "Invention-Regular";
    padding: .5rem 1.25rem;
    background-color: #00857c;
    color: #ffffff;
    border: 1px solid #b9c9db;
    -webkit-box-shadow: 0px -10px 30px 5px rgb(178, 216, 213);
    -moz-box-shadow: 0px -10px 30px 5px rgb(178, 216, 213);
    box-shadow: 0px -10px 30px 5px rgb(178, 216, 213);


    img {
      width: auto;
      height: 2rem;
      margin-left: 1.25rem;
    }


  }
}

.footer {
  .button-main-component {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: .925rem;
    font-family: "Invention-Regular";
    padding: .4rem .75rem .25rem;
    margin: 0 1rem 0 0;
    background-color: #00857c;
    color: #ffffff;
    border: 1px solid #b9c9db;

    p {
      min-width: 80%;
    }


    img {
      width: auto;
      height: 2rem;
      margin-left: .5rem;
    }
  }
}


.quiz {
  .button-main-component {
    display: flex;
    align-items: center;
    font-size: .925rem;
    font-family: "Invention-Regular";
    padding: .4rem 1.125rem .25rem;
    margin: 0 2.5em;
    background-color: #00857c;
    color: #ffffff;
    border: none;
    -webkit-box-shadow: 0px 0px 40px 10px rgba(3, 100, 94, 0.28);
    -moz-box-shadow: 0px 0px 40px 10px rgba(3, 100, 94, 0.28);
    box-shadow: 0px 0px 40px 10px rgba(3, 100, 94, 0.28);

    p {
      min-width: 80%;
    }

    img {
      width: auto;
      height: 2rem;
      margin-left: .5rem;
    }
  }
}


.disabled {
  opacity: .2;
}
</style>
