<template>
  <v-row no-gutters>
    <Quiz :quizId="1" @survey-loader="handleSurvey" />
  </v-row>
</template>

<script>
import Quiz from '@/components/Quiz/QuizComponent'

export default {
  name: 'QuizView',
  components: {
    Quiz
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path', 'survey-loader'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01066 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', true);
    this.$emit('person-path', require('@/assets/img/main/main-menu-person.png'));
  },
  methods: {
    handleSurvey(surveyType) {
      this.$emit('survey-loader', surveyType);
    },
  },
}
</script>

<style lang="scss"></style>
