<template>
  <div class="dialog-content">
    <img :src="require('@/assets/img/educational-materials/left-arrow.png')" v-if="activeSlideIndex > 0"
      class="arrow arrow-left" @click="updateSlideIndex(-1)">
    <div class="dialog-content__image">
      <img :src="require('@/assets/img/educational-materials/' + slidesData[activeSlideIndex].image)"
        :usemap="'#map-' + activeSlideIndex" ref="dialogContentImage" />
      <div v-if="debug">
        <div v-for="(popupArea, popupKey) in slidesData[activeSlideIndex].popups" :key="popupKey"
          class="dialog-content__area-shape" :style="{
            left: Math.ceil((popupArea.cords[0] - popupArea.cords[2]) * scaleRatio) + 'px',
            top: Math.ceil((popupArea.cords[1]) * scaleRatio) + 'px',
            width: Math.ceil((popupArea.cords[2] * 2) * scaleRatio) + 'px',
            height: Math.ceil((popupArea.cords[2] * 2) * scaleRatio) + 'px',
            borderRadius: '50%',
          }">
        </div>
      </div>
      <map :name="'map-' + activeSlideIndex">
        <area v-for="(popupArea, popupKey) in slidesData[activeSlideIndex].popups" :key="popupKey"
          :shape="popupArea.shape"
          :coords="Math.ceil(popupArea.cords[0] * scaleRatio) + ',' + Math.ceil(popupArea.cords[1] * scaleRatio) + ',' + Math.ceil(popupArea.cords[2] * scaleRatio)"
          @click="openSubDialog(popupKey, popupArea)" />
      </map>
    </div>
    <img :src="require('@/assets/img/educational-materials/right-arrow.png')"
      v-if="activeSlideIndex < slidesData.length - 1" class="arrow arrow-right" @click="updateSlideIndex(1)">
  </div>
</template>

<script>
export default {
  name: 'DialogContentComponent',
  props: {
    slidesData: Object,
    activeSlideIndex: Number,
    debug: Boolean,
  },
  emits: ['slide-index-update', 'clicked-popup'],
  methods: {
    updateSlideIndex(value) {
      this.calculateScaleRatio();
      this.$emit('slide-index-update', this.activeSlideIndex + value);
    },
    openSubDialog(index) {
      this.$emit('clicked-popup', index);
    },
    calculateScaleRatio() {
      setTimeout(() => {
        const imageElement = this.$refs.dialogContentImage;
        if (imageElement) {
          const naturalWidth = imageElement.naturalWidth;
          const currentWidth = imageElement.clientWidth;
          this.scaleRatio = (currentWidth / naturalWidth);
        }
      }, 100);
    },
  },
  mounted() {
    this.calculateScaleRatio();
  },
  data() {
    return {
      scaleRatio: 0,
    }
  },
}
</script>

<style lang="scss">
.dialog-content {
  border-radius: 0 .5rem 0 .5rem;

  &__image {
    position: relative;

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 0 0 0 .5rem;
    }
  }

  &__area-shape {
    position: absolute;
    border: 1px solid red;
    opacity: 0.5;
    pointer-events: none;
    z-index: 1000;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding-left: .5rem;
    padding-right: .5rem;
    width: 5%;
    height: auto;
    z-index: 1000;

    &.arrow-left {
      left: 0;
    }

    &.arrow-right {
      right: 0;

    }
  }
}
</style>
