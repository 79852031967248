<template>
  <router-link :to="{ name: urlPath }" class="button-outline-component"
    v-bind:class="{ 'link-active': this.$route.name.includes(urlPath) }">
    <div class="button-outline" v-html="title"></div>
  </router-link>
</template>

<script>
export default {
  name: 'ButtonOutline',
  props: {
    title: String,
    urlPath: String,
  },
}
</script>

<style lang="scss">
.button-outline-component {
  color: #ffffff;

  &.link-active {
    color: #01d0cf;

    .button-outline {
      border: 1px solid #01d0cf !important;
    }
  }

  .button-outline {
    border: 1px solid #ffffff;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .3rem .5rem;
    font-size: .7rem;
    font-family: "Invention-Regular";
    margin: 0 .65rem 0 0;
  }
}
</style>
