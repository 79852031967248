<template>
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12">
      <MedicalMenuHeader title="Potrójnie ujemny Rak Piersi" sub-title="Materiały Edukacyjne"
        extra-sub-title="/ Wczesne Stadium TNBC" imgPath="breast-cancer.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="data-area data-area-educational-materials d-flex flex-wrap align-end justify-center">
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/skutecznosc.png')" alt=""
        class="image-educational-materials-element" @click="firstDialogState = $event">
      <dialog-mailing :dialog-state="firstDialogState" @input="firstDialogState = $event"
        img-path="brest-cancer/early/4/1.png" />
    </v-col>
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/schemat.png')" alt=""
        class="image-educational-materials-element" @click="secondDialogState = $event">
      <dialog-mailing :dialog-state="secondDialogState" @input="secondDialogState = $event"
        img-path="brest-cancer/early/1/1.png" />
    </v-col>
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/profil-bezpieczenstwa.png')" alt=""
        class="image-educational-materials-element" @click="thirdDialogState = $event">
      <dialog-mailing :dialog-state="thirdDialogState" @input="thirdDialogState = $event"
        img-path="brest-cancer/early/3/1.png" />
    </v-col>
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/profil.png')" alt=""
        class="image-educational-materials-element" @click="fourthDialogState = $event">
      <dialog-mailing :dialog-state="fourthDialogState" @input="fourthDialogState = $event"
        img-path="brest-cancer/early/5/1.png" />
    </v-col>
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/wskazanie.png')" alt=""
        class="image-educational-materials-element" @click="fifthDialogState = $event">
      <dialog-mailing :dialog-state="fifthDialogState" @input="fifthDialogState = $event"
        img-path="brest-cancer/early/2/1.png" />
    </v-col>
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/dawkowanie.png')" alt=""
        class="image-educational-materials-element" @click="sixDialogState = $event">
      <dialog-mailing :dialog-state="sixDialogState" @input="sixDialogState = $event"
        img-path="brest-cancer/early/6/1.png" />
    </v-col>
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/keynote-522.png')" alt=""
        class="image-educational-materials-element" @click="sevenDialogState = true; $refs.sevenMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="sevenDialogState" @input="sevenDialogState = $event"
        ref="sevenMaterialComponent" :materials-data="sevenMaterialsData" />
    </v-col>
    <v-col cols="6" align-self="flex-end">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import ButtonMain from "@/components/Buttons/ButtonMainComponent";
import DialogMailing from "@/components/Section/DialogMailingComponent";
import DialogMainSection from "@/components/Section/DialogMainSectionComponent";

export default {
  name: 'BrestCancerEducationalMaterialsEarlyTnbcView',
  components: {
    MedicalMenuHeader,
    ButtonMain,
    DialogMailing,
    DialogMainSection,
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  data() {
    return {
      firstDialogState: false,
      secondDialogState: false,
      thirdDialogState: false,
      fourthDialogState: false,
      fifthDialogState: false,
      sixDialogState: false,
      sevenDialogState: false,
      sevenMaterialsData: {
        "menuInFooter": true,
        "menu": [],
        "slides": [
          {
            "image": "brest-cancer/early/7/1.png",
            "popups": []
          },
          {
            "image": "brest-cancer/early/7/2.png",
            "popups": []
          },
          {
            "image": "brest-cancer/early/7/3.png",
            "popups": []
          },
          {
            "image": "brest-cancer/early/7/4.png",
            "popups": []
          },
          {
            "image": "brest-cancer/early/7/5.png",
            "popups": []
          },
          {
            "image": "brest-cancer/early/7/6.png",
            "popups": []
          },
          {
            "image": "brest-cancer/early/7/7.png",
            "popups": []
          }
        ]
      },
    }
  },
  created() {
    this.$emit('accept-number', 'PL-KEY-01067 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/breast-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss"></style>
