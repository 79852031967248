<template>
  <div class="d-flex flex-wrap align-content-space-around h-100">
    <p class="single-video-title">{{ title }}</p>
    <div class="single-video-player">
      <iframe width="640" height="360" :src="videoUrl + '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&cc_load_policy=0'" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay;  picture-in-picture; web-share"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleVideo',
  props: {
    videoUrl: String,
    title: String,
  },
}
</script>

<style lang="scss">
.single-video-title {
  padding-left: 4rem;
  padding-bottom: 1rem;
}

.single-video-player {
  position: relative;
  width: 27rem;
  height: 15.19rem;
  margin: 0 auto;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
