<template>
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12">
      <MedicalMenuHeader title="Rak nerkowokomórkowy" sub-title="Materiały Edukacyjne"
        imgPath="renal-cell-carcinoma-cancer.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="data-area data-area-educational-materials d-flex flex-wrap align-content-space-between">
   <v-col cols="12">
    <v-row>
      <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/rcc.png')" alt=""
        class="image-educational-materials-element"
        @click="firstDialogState = true;  $refs.firstMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="firstDialogState" @input="firstDialogState = $event"
        ref="firstMaterialComponent" :materials-data="firstMaterialsData"   />
      </v-col>
      <v-col cols="6">
        <img :src="require('@/assets/img/educational-materials/adhezja-nerka.png')" alt=""
          class="image-educational-materials-element"
          @click="secondDialogState = true; $refs.secondMaterialComponent.resetIndex()">
        <dialog-main-section :dialog-state="secondDialogState" @input="secondDialogState = $event"
          ref="secondMaterialComponent" :materials-data="secondMaterialsData"/>
      </v-col>
      <v-col cols="6">
        <a href="/pdf/Program-Lekowy-B10.pdf" target="_blank">
          <img :src="require('@/assets/img/educational-materials/program-lekowy-b10.png')" alt=""
            class="image-educational-materials-element" >
        </a>
      </v-col>
    </v-row>
   </v-col>
    <v-col cols="12">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import ButtonMain from "@/components/Buttons/ButtonMainComponent";
import DialogMainSection from "@/components/Section/DialogMainSectionComponent";

export default {
  name: 'RenalCellCarcinomaEducationalMaterialsView',
  components: {
    MedicalMenuHeader,
    ButtonMain,
    DialogMainSection,
  },
  data() {
    return {
      firstDialogState: false,
      secondDialogState: false,
      firstMaterialsData: {
        "menuInFooter": false,
        "class": 'renal-cell-carcinoma-cancer-rcc-m1',
        "menu": [
          { "title": "Raport z badania", "slides": [2] },
          { "title": "Projekt badania", "slides": [3] },
          { "title": "Charakterystyka pacjenta", "slides": [4] },
          { "title": "Wyniki", "slides": [5, 6, 7, 8, 9] },
          { "title": "Bezpieczeństwo", "slides": [10] },
          { "title": "Mechanizm działania", "slides": [11] },
          { "title": "Wytyczne", "slides": [12] },
          { "title": "Podsumowanie", "slides": [13] }
        ],
        "slides": [
          {
            "image": "renal-cell-carcinoma-cancer/1/1.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/2.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/3.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/4.png",
            "popups": [
              {
                "cords": [500, 1600, 300],
                "shape": "circle",
                "closeCords": [2396, -10, 100],
                "closeShape": "circle",
                "image": "renal-cell-carcinoma-cancer/1/4-1.png"
              },
            ]
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/5.png",
            "popups": [
            {
                "cords": [500, 1600, 300],
                "shape": "circle",
                "closeCords": [2396, -10, 100],
                "closeShape": "circle",
                "image": "renal-cell-carcinoma-cancer/1/5-1.png"
              },
            ]
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/6.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/7.png",
            "popups": [
              {
                "cords": [1150, 550, 100],
                "shape": "circle",
                "closeCords": [2380, -10, 100],
                "closeShape": "circle",
                "image": "renal-cell-carcinoma-cancer/1/7-1.png"
              },
              {
                "cords": [1830, 550, 100],
                "shape": "circle",
                "closeCords": [2380, -10, 100],
                "closeShape": "circle",
                "image": "renal-cell-carcinoma-cancer/1/7-2.png"
              },
              {
                "cords": [2530, 550, 100],
                "shape": "circle",
                "closeCords": [2380, -10, 100],
                "closeShape": "circle",
                "image": "renal-cell-carcinoma-cancer/1/7-3.png"
              },
            ]
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/8.png",
            "popups": [
            {
                "cords": [1450, 550, 100],
                "shape": "circle",
                "closeCords": [2380, -10, 100],
                "closeShape": "circle",
                "image": "renal-cell-carcinoma-cancer/1/8-1.png"
              },
              {
                "cords": [2500, 550, 100],
                "shape": "circle",
                "closeCords": [2380, -10, 100],
                "closeShape": "circle",
                "image": "renal-cell-carcinoma-cancer/1/8-2.png"
              },
            ]
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/9.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/10.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/11.png",
            "popups": [
              {
                "cords": [2480, 640, 100],
                "shape": "circle",
                "closeCords": [2380, -10, 100],
                "closeShape": "circle",
                "image": "renal-cell-carcinoma-cancer/1/11-1.png"
              },
            ]
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/12.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/14.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/15.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/1/16.png",
            "popups": []
          },
        ]
      },
      secondMaterialsData: {
        "menuInFooter": true,
        "class": 'renal-cell-carcinoma-cancer-rcc-m2',
        "menu": [
        ],
        "slides": [
          {
            "image": "renal-cell-carcinoma-cancer/2/1.png",
            "popups": []
          },
          {
            "image": "renal-cell-carcinoma-cancer/2/2.png",
            "popups": []
          }
        ]
      },
    }
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01067 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/renal-cell-carcinoma-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss">
.renal-cell-carcinoma-cancer-rcc-m1 {
  background-color: #c6daae;

  .dialog-menu {
    font-family: "Invention-Bold";
    font-size: 0.55rem;
    color: #c6daae;
    padding-right: 2%;

    div {
      position: relative;
      float: left;
      width: 12.5%;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      z-index: 1;
      background-color: #6cc04a;
      border-bottom-right-radius: 5rem;

      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0%;
        transform: skew(-28deg);
        border-bottom-right-radius: 0.25rem;
        -webkit-box-shadow: 20px -6px 30px -4px rgb(63, 102, 45);
        -moz-box-shadow: 20px -6px 30px -4px rgb(63, 102, 45);
        box-shadow: 20px -6px 30px -4px rgb(63, 102, 45);
        width: 115%;
        height: 100%;
        background: #6cc04a;
      }


      &:first-child {
        z-index: 8;
        margin: 0;
        padding-left: 0;
        padding-right: .25rem;

        &::after {
          width: 80%;
          height: 100%;
          z-index: -6;
        }

      }

      &:nth-child(2) {
        z-index: 7;
        padding: 0 1rem 0 1.5rem;

        &::after {
          width: 117%;
          height: 100%;
          z-index: -5;
        }
      }

      &:nth-child(3) {
        z-index: 6;
        padding-left: .5rem;

        &::after {
          z-index: -4;
        }
      }

      &:nth-child(4) {
        z-index: 5;
        padding-left: .125rem;

        &::after {
          z-index: -3;
        }
      }

      &:nth-child(5) {
        z-index: 4;
        padding-left: 0.25rem;

        &::after {
          z-index: -2;
        }
      }

      &:nth-child(6) {
        z-index: 3;
        padding: 0 0.5rem;

        &::after {
          z-index: -2;
        }
      }

      &:nth-child(7) {
        z-index: 2;

        &::after {
          z-index: -2;
        }
      }

      &:nth-child(8) {
        z-index: 1;
        border-bottom-right-radius: 100%;
        padding-left: .125rem;

        &::after {
          z-index: -1;
          border-top-right-radius: 0.5rem;
        }
      }


      &.active {
        background-color: #2c6d3c;

        &::after {
          background-color: #2c6d3c;
        }
      }

    }
  }
}
</style>

