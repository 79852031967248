import BrestCancerHomeView from "@/views/Medical/BrestCancer/BrestCancerHomeView";
import BrestCancerSubMenuView from "@/views/Medical/BrestCancer/BrestCancerSubMenuView";
import BrestCancerChplView from "@/views/Medical/BrestCancer/BrestCancerChplView";
import BrestCancerVideoView from "@/views/Medical/BrestCancer/BrestCancerVideoView";
import BrestCancerEducationalMaterialsLateTnbcView from "@/views/Medical/BrestCancer/BrestCancerEducationalMaterialsLateTnbcView";
import BrestCancerEducationalMaterialsEarlyTnbcView from "@/views/Medical/BrestCancer/BrestCancerEducationalMaterialsEarlyTnbcView";

const routes = [
    {
        path: '/potrojnie-ujemny-rak-piersi',
        name: 'brest_cancer',
        component: BrestCancerHomeView,
    },
    {
        path: '/potrojnie-ujemny-rak-piersi/materialy-edukacyjne',
        name: 'brest_cancer_materials_menu',
        component: BrestCancerSubMenuView,
    },
    {
        path: '/potrojnie-ujemny-rak-piersi/materialy-edukacyjne/wczesne-tnbc',
        name: 'brest_cancer_educational_materials_early_tnbc',
        component: BrestCancerEducationalMaterialsEarlyTnbcView,
    },
    {
        path: '/potrojnie-ujemny-rak-piersi/materialy-edukacyjne/pozne-tnbc',
        name: 'brest_cancer_educational_materials_late_tnbc',
        component: BrestCancerEducationalMaterialsLateTnbcView,
    },
    {
        path: '/potrojnie-ujemny-rak-piersi/video',
        name: 'brest_cancer_video',
        component: BrestCancerVideoView,
    },
    {
        path: '/potrojnie-ujemny-rak-piersi/chpl',
        name: 'brest_cancer_chpl',
        component: BrestCancerChplView,
    },
];

export default routes;
