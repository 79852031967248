<template>
  <div class="live-stream d-flex flex-column">
    <MedicalMenuHeader title="Live Stream" imgPath="live-stream-ico.png" />
  </div>
  <v-row no-gutters class="live-stream__area d-flex flex-wrap justify-space-around w-100 ">
    <v-col cols="5" class="live-stream__area--box">
      <a href="https://viamedica.clickmeeting.com/ptok2024-msd" target="_blank">
        <img :src="require('@/assets/img/medical/live-stream-ico-button.png')" />
        <p>NDRP</p>
      </a>
    </v-col>
    <v-col cols="5" class="live-stream__area--box">
      <a href="https://viamedica.clickmeeting.com/ptok2024-msd" target="_blank">
        <img :src="require('@/assets/img/medical/live-stream-ico-button.png')" />
        <p>KOBIECE NOWOTWORY</p>
      </a>
    </v-col>
    <v-col cols="12" align-self="end">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuHeader from "@/components/Blocks/MedicalMenuHeaderComponent";
import ButtonMain from "@/components/Buttons/ButtonMainComponent";

export default {
  name: 'LiveStreamView',
  components: {
    MedicalMenuHeader,
    ButtonMain,
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01082 (wyd. 08.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/main-menu-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss">
.live-stream__area {
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  background-image: linear-gradient(190deg, rgba(255, 255, 255, 1) 46%, rgba(229, 239, 238, 1) 100%);
  border-radius: 0em 1em;
  padding: 2% 1.5rem;
  margin-bottom: 0.75rem !important;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: -.75rem;
    right: -.75rem;
    width: .75rem;
    height: .75rem;
    background-color: #01d0cf;
    z-index: -1;
  }

  &--box {
    font-family: "Invention-Bold";
    font-size: .875rem;
    line-height: 1.75rem;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0 .5rem 0 .5rem;
    -webkit-box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
    -moz-box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
    box-shadow: 0px 0px 30px 12px rgba(178, 216, 213, 1);
    margin: 2vh 1vw;
    height: 31%;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    img {
      width: 2.5rem;
      margin: 1rem auto .75rem;
      display: block;
    }

    p {

      &:first-of-type {
        display: inline-block;
        width: auto;
        border-bottom: 2px solid #00a59d;
      }

      span {
        font-size: .75rem;
      }
    }

  }

  .back-button {
    float: right;
    margin-right: -.75rem;
  }
}
</style>
