<template>
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12">
      <MedicalMenuHeader title="Potrójnie ujemny Rak Piersi" sub-title="Materiały Edukacyjne"
        extra-sub-title="/ Zaawansowane stadium TNBC" imgPath="breast-cancer.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="data-area data-area-educational-materials d-flex justify-center align-center">
    <v-col cols="9">
      <img :src="require('@/assets/img/educational-materials/breast-cancer-advanced.png')" alt=""
        class="image-educational-materials" @click="firstDialogState = true; $refs.firstMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="firstDialogState" @input="firstDialogState = $event"
        ref="firstMaterialComponent" :materials-data="firstMaterialsData" />
    </v-col>
    <v-col cols="12" align-self="end">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import ButtonMain from "@/components/Buttons/ButtonMainComponent";
import DialogMainSection from "@/components/Section/DialogMainSectionComponent";

export default {
  name: 'BrestCancerEducationalMaterialsLateTnbcView',
  components: {
    MedicalMenuHeader,
    ButtonMain,
    DialogMainSection,
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  data() {
    return {
      firstDialogState: false,
      firstMaterialsData: {
        "menuInFooter": true,
        "class": 'brest-cancer-late-m1',
        "menu": [],
        "slides": [
          {
            "image": "brest-cancer/advance/1/1.png",
            "popups": []
          },
          {
            "image": "brest-cancer/advance/1/2.png",
            "popups": []
          },
          {
            "image": "brest-cancer/advance/1/3.png",
            "popups": []
          },
          {
            "image": "brest-cancer/advance/1/4.png",
            "popups": []
          },
          {
            "image": "brest-cancer/advance/1/5.png",
            "popups": []
          },
          {
            "image": "brest-cancer/advance/1/6.png",
            "popups": []
          },
          {
            "image": "brest-cancer/advance/1/7.png",
            "popups": []
          }
        ]
      }
    }
  },
  created() {
    this.$emit('accept-number', 'PL-KEY-01067 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/breast-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss"></style>
