<template>
  <metainfo></metainfo>

  <div class="full-screen" @click="fullScreen"><img :src="fullScreenImagePath"></div>
  <div class="accept-number">{{ acceptNumber }}</div>
  <div class="footer__links">
    <a href="https://www.essentialaccessibility.com/msd">
        <img :src="require('@/assets/img/main/essential-icon.png')" class="essential-ico" />
    </a>
    <a href="https://www.msd.com/our-commitment-to-accessibility-for-all/">
      <p>Accessibility</p>
    </a>
  </div>
  <div class="logo">
    <router-link :to="{ name: 'home' }">
      <img :src="logoPath" />
    </router-link>
  </div>
  <div class="content">
    <v-row no-gutters class="content-area">
      <v-col cols="4">
        <div class="person">
          <img :src="personPath" />
        </div>
      </v-col>
      <v-col cols="8" class="content-box d-flex flex-column h-100">
        <router-view @accept-number="handleAcceptNumber" @person-path="updatePersonPath"
          @update-homepage-footer="updateHomepageFooter" @survey-loader="handleSurvey" />
        <v-row no-gutters class="footer flex-grow-0 align-self-end">
          <v-col cols="12">
            <footer-menu-section :homepage-footer="homepageFooter" @survey-loader="handleSurvey" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <button style="display: none" id="embed-button"/>
</template>

<script>
import FooterMenuSection from "@/components/Section/FooterMenuSectionComponent";

export default {
  components: {
    FooterMenuSection,
  },
  data() {
    return {
      logoPath: require('@/assets/img/main/keytruda-logo.png'),
      personPath: require('@/assets/img/main/main-menu-person.png'),
      fullScreenImagePath: require('@/assets/img/fullscreen/full-screen-btn.png'),
      isFullScreenMode: true,
      acceptNumber: '',
      homepageFooter: true,
      screverHiddenButton: '',
      screverHiddenButtonReplacement: '',
    };
  },
  updated() {
    //Handle clone of button for removing listeners
    this.screverHiddenButtonReplacement = document.getElementById('embed-button').cloneNode(true);
  },
  methods: {
    handleAcceptNumber(value) {
      this.acceptNumber = value;
    },
    handleSurvey(surveyType) {
      //Removing js from screver
      let screverScript = document.getElementById('screver-survey');
      if (screverScript) {
        screverScript.parentNode.removeChild(screverScript);
      }

      //Removing css styles from screver
      let links = document.querySelectorAll('link[rel="stylesheet"]');
      links.forEach(link => {
        if (link.href.includes('https://app.screver.com/embed-styles.css')) {
          link.parentNode.removeChild(link);
        }
      });

      //Removing listeners on button
      this.screverHiddenButton = document.getElementById('embed-button');
      this.screverHiddenButton.parentNode.replaceChild(this.screverHiddenButtonReplacement.cloneNode(true), this.screverHiddenButton);

      //Decide about survey
      let script = document.createElement('script');
      if (surveyType === 1) {
        script.src = 'https://go.screver.com/scripts/embed-survey?s=target/58948d3b-cf08-4d24-a56d-5437c1e472ee&t=slider&c=msd-pl-cx&btnL=Pass the survey&i=annotation&sp=Left&w=100%&h=800px&hH=true&hF=true';
      } else if (surveyType === 2) {
        script.src = 'https://go.screver.com/scripts/embed-survey?s=target/5ed77771-5e84-4b6d-9f75-a1dd2dc333e7&t=slider&c=msd-pl-cx&btnL=Pass the survey&i=annotation&sp=Left&w=100%&h=800px&hH=true&hF=true';
      }
      script.id = 'screver-survey';
      script.async = true;
      document.head.appendChild(script);

      //Trigger button for screver
      script.onload = function () {
        let surveyButton = document.getElementById('embed-button');
        surveyButton.click();
      };

      script.onerror = function () {
        console.log('We can\'t load survey. Please check your internet connection of firewall settings.')
      }
    },
    updatePersonPath(value) {
      this.personPath = value;
    },
    fullScreen() {
      this.$fullscreen.toggle();
      if (this.isFullScreenMode) {
        this.isFullScreenMode = false;
        this.fullScreenImagePath = require('@/assets/img/fullscreen/full-screen-close-btn.png');
      } else {
        this.isFullScreenMode = true;
        this.fullScreenImagePath = require('@/assets/img/fullscreen/full-screen-btn.png');
      }
    },
    updateHomepageFooter(value) {
      this.homepageFooter = value;
    }
  },
  metaInfo() {
    return {
      title: 'KEYTRUDA',
      meta: [
        { name: 'msapplication-TileColor', content: '#00857c' },
        { name: 'theme-color', content: '#ffffff' },
      ],
      link: [
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
        { rel: 'manifest', href: '/site.webmanifest' },
        { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '"#00857c' },
      ],
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Invention-Regular';
  src:
    url('~@/assets/font/Invention-Regular.woff2') format('woff2'),
    url('~@/assets/font/Invention-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Invention-Bold';
  src:
    url('~@/assets/font/Invention-Bold.woff2') format('woff2'),
    url('~@/assets/font/Invention-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}


* {
  box-sizing: border-box;
}

:not(:root):fullscreen::backdrop {
  background: none;
}

html,
body,
#app {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Invention-Regular', sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  font-size: 16px !important;
}

@media only screen and (max-width: 1279px) {
  html {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1280px) {
  html {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 1920px) {
  html {
    font-size: 28px !important;
  }
}

@media only screen and (min-width: 2560px) {
  html {
    font-size: 38px !important;
  }
}

@media only screen and (min-width: 3440px) {
  html {
    font-size: 48px !important;
  }
}



body {
  text-align: center;
  background-image: url("~@/assets/img/main/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #00857c;
}

a {
  text-decoration: none;
  color: #00857c;
}

img {
  background-color: transparent;
}

.accept-number {
  position: absolute;
  left: -2.75rem;
  bottom: 4.5rem;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  font-size: .5rem;
  color: white;
  z-index: 10;
}

.footer__links {
  position: absolute;
  left: 1.75vw;
  bottom: 2vh;
  z-index: 9999;
  display: flex;
  align-items: center;

  a {
    font-size: 0.625rem;
    font-family: "Invention-Bold", sans-serif;
    color: #ffffff;
  }

  .essential-ico {
      width: auto;
      height: 4vh;
      display: block;
      margin-right: 1vw;
  }
}


.content {
  height: 100%;
  padding: 4rem 1.75rem 1rem 1rem;

  .content-area {
    height: 100%;
    width: 100%;
    margin: 0 !important;

    .content-box {
      height: 100%;
      padding-left: 2.5% !important;

      .content-box-main {
        padding-left: 4% !important;
        padding-right: 3% !important;
      }

      .home-category {
        padding-left: 4% !important;
      }

      .home-category-submenu {
        padding-left: 4%;
      }
      
    }
  }
}


.data-area {
  background-color: rgba(255, 255, 255, 1);
  background-image: linear-gradient(190deg, rgba(255, 255, 255, 1) 46%, rgba(229, 239, 238, 1) 100%);
  border-radius: 0em 1em;
  padding: 2% 1.5rem;
  margin-bottom: .75rem !important;
  position: relative;
  text-align: left;
  font-family: "Invention-Regular";
  font-size: 1rem;

  p {
    width: auto;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -.75rem;
    right: -.75rem;
    width: .75rem;
    height: .75rem;
    background-color: #01d0cf;
    z-index: -1;
  }

  &.data-area-educational-materials {
    padding-top: 1rem;

    .image-educational-materials {
      display: block;
      width: auto;
      height: 45vh;
      margin: 0 auto;
    }

    .image-educational-materials-element {
      display: block;
      width: 100%;
      padding: 0 1.5rem;
      height: auto;
      margin: 0 auto;
    }
  }

  &.data-area-patient-cases {
    font-family: "Invention-Bold";
    font-size: 1rem;
  }

  .back-button {
    float: right;
    margin-right: -.75rem;
  }


}


.person {
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;

  img {
    display: block;
    height: 100%;
  }
}

.logo {
  position: absolute;
  right: 0;
  top: 0;

  img {
    width: auto;
    height: 5rem;
  }
}

.full-screen {
  position: absolute;
  top: 0;
  left: 0;

  img {
    height: 5rem;
    width: auto;
  }
}

.footer {
  width: 100%;

  .footer-logo {

    img {
      display: block;
      width: auto;
      height: 2.75rem;
    }
  }

  .footer-outline {
    padding-left: 1%;
  }

  .footer-separator {
    width: 100%;
    border-top: 1px solid #00857c;
    opacity: 0.5;
    margin: .75rem 0 1.25rem;
  }
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00857c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00857c;
}

.fullWidth {
  width: 33.5vw !important;
  background: none !important;
}

.slider-close-btn {
  width: 2em;
  position: absolute;
  z-index: 999999;
  top: 2.5vh !important;
  left: 29.5vw;
  background-color: #00857c;
  border-radius: 50%;
  padding: .5em;
}

.slider-container {
    width: 33.5vw !important;
    z-index: 9999;
}

</style>
