import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ForOForView from '@/views/404View.vue'
import QuizView from '@/views/QuizView.vue'
import LiveStreamView from '@/views/LiveStreamView.vue'

import lingCancerRoutes from './lungCancer';
import brestCancerRoutes from './brestCancer';
import headAndNeckCancerRoutes from './headAndNeckCancer';
import melanomaRoutes from './melanoma';
import renalCellCarcinomaRoutes from './renalCellCarcinoma';
import cervicalCancerRoutes from './cervicalCancer';

const routes = [
    ...lingCancerRoutes,
    ...brestCancerRoutes,
    ...headAndNeckCancerRoutes,
    ...melanomaRoutes,
    ...renalCellCarcinomaRoutes,
    ...cervicalCancerRoutes,
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/404',
        name: 'four_o_four',
        component: ForOForView,
    },
    {
        path: '/quiz',
        name: 'quiz',
        component: QuizView,
    },
    {
        path: '/live-stream',
        name: 'live_stream',
        component: LiveStreamView,
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
