<template>
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12">
      <MedicalMenuHeader title="Nowotwory Głowy i Szyi" sub-title="Materiały Edukacyjne"
        imgPath="head-and-neck-cancers.png" />
    </v-col>
  </v-row>
  <v-row no-gutters class="data-area data-area-educational-materials d-flex justify-center align-center">
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/head-and-neck-cancer-1.png')" alt=""
        class="image-educational-materials-element"
        @click="firstDialogState = true; $refs.firstMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="firstDialogState" @input="firstDialogState = $event"
        ref="firstMaterialComponent" :materials-data="firstMaterialsData"/>
    </v-col>
    <v-col cols="6">
      <img :src="require('@/assets/img/educational-materials/head-and-neck-cancer-2.png')" alt=""
        class="image-educational-materials-element"
        @click="secondDialogState = true; $refs.secondMaterialComponent.resetIndex()">
      <dialog-main-section :dialog-state="secondDialogState" @input="secondDialogState = $event"
        ref="secondMaterialComponent" :materials-data="secondMaterialsData"/>
    </v-col>
    <v-col cols="12" align-self="end">
      <div @click="goBack" class="back-button w-auto">
        <button-main :left-icon="false" title="Powrót" img-path="return-icon.png" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MedicalMenuHeader from '@/components/Blocks/MedicalMenuHeaderComponent'
import ButtonMain from "@/components/Buttons/ButtonMainComponent";
import DialogMainSection from "@/components/Section/DialogMainSectionComponent";

export default {
  name: 'HeadAndNeckCancerEducationalMaterialsView',
  components: {
    MedicalMenuHeader,
    ButtonMain,
    DialogMainSection,
  },
  data() {
    return {
      firstDialogState: false,
      secondDialogState: false,
      firstMaterialsData: {
        "menuInFooter": false,
        "class": 'head-and-neck-cancer-m1',
        "menu": [
          { "title": "Plan badania", "slides": [2] },
          { "title": "OS dla CPS >= 1", "slides": [3] },
          { "title": "OS dla CPS >= 20", "slides": [5] },
          { "title": "DOR i ORR", "slides": [7] },
          { "title": "Bezpieczeństwo", "slides": [8] },
          { "title": "Wnioski", "slides": [9] }
        ],
        "slides": [
          {
            "image": "head-and-neck-cancer/1/1.png",
            "popups": [
              {
                "cords": [2585, 1575, 100],
                "shape": "circle",
                "closeCords": [2400, 50, 100],
                "closeShape": "circle",
                "image": "head-and-neck-cancer/1/1-1.png"
              }
            ]
          },
          {
            "image": "head-and-neck-cancer/1/2.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/1/3.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/1/4.png",
            "popups": [
              {
                "cords": [2480, 280, 100],
                "shape": "circle",
                "closeCords": [2400, 50, 100],
                "closeShape": "circle",
                "image": "head-and-neck-cancer/1/4-1.png"
              }
            ]
          },
          {
            "image": "head-and-neck-cancer/1/5.png",
            "popups": [
              {
                "cords": [2480, 285, 100],
                "shape": "circle",
                "closeCords": [2400, 50, 100],
                "closeShape": "circle",
                "image": "head-and-neck-cancer/1/5-1.png"
              }
            ]
          },
          {
            "image": "head-and-neck-cancer/1/6.png",
            "popups": [
              {
                "cords": [2480, 285, 100],
                "shape": "circle",
                "closeCords": [2400, 50, 100],
                "closeShape": "circle",
                "image": "head-and-neck-cancer/1/6-1.png"
              }
            ]
          },
          {
            "image": "head-and-neck-cancer/1/7.png",
            "popups": [
              {
                "cords": [2480, 285, 100],
                "shape": "circle",
                "closeCords": [2400, 50, 100],
                "closeShape": "circle",
                "image": "head-and-neck-cancer/1/7-1.png"
              }
            ]
          },
          {
            "image": "head-and-neck-cancer/1/8.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/1/9.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/1/10.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/1/11.png",
            "popups": []
          }
        ]
      },
      secondMaterialsData: {
        "menuInFooter": true,
        "class": 'head-and-neck-cancer-m2',
        "menu": [
          { "title": "MSD", "slides": [0] },
          { "title": " ", "slides": [0] },
          { "title": " ", "slides": [0] },
          { "title": " ", "slides": [0] },
          { "title": "Informacja o leku", "slides": [1] },
        ],
        "slides": [
          {
            "image": "head-and-neck-cancer/2/1.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/2.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/3.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/4.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/5.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/6.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/7.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/8.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/9.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/10.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/11.png",
            "popups": []
          },
          {
            "image": "head-and-neck-cancer/2/12.png",
            "popups": []
          }
        ]
      },
    }
  },
  emits: ['accept-number', 'update-homepage-footer', 'person-path'],
  created() {
    this.$emit('accept-number', 'PL-KEY-01067 (wyd. 07.2023)');
    this.$emit('update-homepage-footer', false);
    this.$emit('person-path', require('@/assets/img/main/head-person.png'));
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss">
.head-and-neck-cancer-m1 {
  .dialog-menu {
    font-family: "Invention-Bold";
    font-size: 0.625rem;
    color: #ffffff;
    background-color: #f4faf6;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff;


    div {
      padding: .625rem;
      border: 1px solid #ffffff;
      width: 16.66%;
      position: relative;

      &:first-child {
        background-color: #70ceb3;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #70ceb3;
          }
        }
      }

      &:nth-child(2) {
        background-color: #80a9af;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #80a9af;
          }
        }
      }

      &:nth-child(3) {
        background-color: #6dc04a;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #6dc04a;
          }
        }
      }

      &:nth-child(4) {
        background-color: #48a648;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #48a648;
          }
        }
      }

      &:nth-child(5) {
        background-color: #048938;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #048938;
          }
        }
      }

      &:nth-child(6) {
        background-color: #025121;
        border-top-right-radius: 0.5rem;

        &.active {
          &::after {
            content: " ";
            position: absolute;
            bottom: -15%;
            left: 0;
            width: 100%;
            height: 7%;
            z-index: 1;
            background-color: #025121;
          }
        }
      }

    }
  }
}

.head-and-neck-cancer-m2 {
  .dialog-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-family: "Invention-Bold";
    font-size: 0.875rem;
    color: #ffffff;
    display: flex;

    div {
      padding: .75rem;
      border: 2px solid #000000;
      flex-grow: 1;
      background-color: rgba(123, 123, 123, 1);
      background-image: linear-gradient(180deg, rgba(123, 123, 123, 1) 0%, rgba(27, 27, 27, 1) 50%, rgba(0, 0, 0, 1) 100%);

      &:first-child {
        border-radius: 0 0 0 .5rem;
      }
    }


  }
}
</style>
