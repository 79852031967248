<template>
  <div class="results d-flex flex-wrap justify-space-between">

    <v-row no-gutters v-for="question in questions" :key="question.question">
      <v-col cols="1" class="question-number">
        <p>{{ question.question }}</p>
      </v-col>
      <v-col cols="11">
        <v-row no-gutters class="question-width">
          <v-col cols="12">{{ question.title }}</v-col>
          <v-col v-for="answer in question.answers" :key="question.question + '-' + answer.id" cols="auto">
            <p class="answers-list"
              v-bind:class="{ 'correct': answer.correct, 'wrong': !answer.correct && answer.id === answers[question.question] }">
              {{ answer.answer }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'QuizResults',
  props: {
    questions: Object,
    answers: Array
  },
};
</script>

<style lang="scss">
.results {
  height: 100%;
  padding: 0 3rem 1rem;
  font-size: 0.65rem;

  .correct {
    font-weight: bold;
    text-decoration: underline;
  }

  .wrong {
    color: #eb3452;
    font-weight: bold;
  }

  .question-width {
    width: 85%;
  }

  .question-number p {
    font-size: 1.125rem;
    line-height: 1rem;
    text-align: center;
    font-family: 'Invention-Bold', sans-serif;
    color: #00857c;
    border: .06rem solid #00857c;
    border-radius: 50%;
    padding: .5rem;
    width: 2.125rem;
  }

  .answers-list {
    font-family: 'Invention-Regular', sans-serif;
    line-height: 1rem;
    text-transform: uppercase;
    flex-grow: 0;
    margin-right: 1.5rem;
    padding: 0;
  }

}
</style>
